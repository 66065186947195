import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import xor from 'lodash/xor'
import {
  IProductAggregationTerm,
  IProductsQuery,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCheckbox,
  LYSInput,
  LYSMenu,
  LYSTypography,
} from '@core/components/Primitives'
import {
  useSearchedFilterValues,
  useSortedFilterValues,
} from '@core/components/ProductCollection/Filters/SimpleFilter/sortAndSearch'
import productFiltersConfig, {
  ProductFilterAttributeConfig,
  ProductFilterAttributeSorting,
} from '@core/config/productFilters'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery, {
  extractUrlValues,
} from '../../useProductCollectionQuery'
import style from './index.module.less'

const toggleStringInArray = (array: string[], value: string) =>
  xor(array, [value])

const defaultAttributeConfig: ProductFilterAttributeConfig = {
  sorting: ProductFilterAttributeSorting.Relevance,
}

const useFilterConfig = (attributeKey: string): ProductFilterAttributeConfig =>
  useMemo(
    () =>
      productFiltersConfig.attributes[attributeKey] || defaultAttributeConfig,
    [attributeKey]
  )

export interface SimpleFilterProps {
  aggregation: IProductAggregationTerm
  filterKey: string
  query: IProductsQuery
  onSetFilter: (key: string, value: string[]) => void
  isBrandFilter?: boolean
  isAwardFilter?: boolean
}

const SimpleFilter: React.FC<SimpleFilterProps> = ({
  aggregation,
  filterKey,
  query,
  onSetFilter,
  isBrandFilter,
  isAwardFilter,
}) => {
  const { t } = useTranslation()
  const {
    onlyOwnBrandsFilterActive,
    onlyAwardsFilterActive,
    setBrandsFilter,
    setAwardsFilter,
  } = useProductCollectionQuery()
  const [onlyOwnBrandsSwitch, setOnlyOwnBrandsSwitch] = useState<boolean>(
    onlyOwnBrandsFilterActive
  )
  const [onlyAwardsSwitch, setOnlyAwardsSwitch] = useState<boolean>(
    onlyAwardsFilterActive
  )

  const [searchString, setSearchString] = useState<string>('')
  const urlValues = extractUrlValues(filterKey, query.filter)
  const attributeConfig = useFilterConfig(filterKey)
  const [checkedValues, setCheckedValues] = useState<string[]>(urlValues)
  const sortedFilterValues = useSortedFilterValues(
    aggregation.filterValues,
    attributeConfig.sorting
  )

  const searchedFilterValues = useSearchedFilterValues(
    sortedFilterValues,
    searchString
  )

  // we need the filter to refresh it's state if the filter was removed outside of component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCheckedValues(urlValues), [JSON.stringify(urlValues)])

  const handleSetFilter = useCallback(() => {
    if (isBrandFilter) {
      setBrandsFilter(onlyOwnBrandsSwitch, filterKey, checkedValues)
    } else if (isAwardFilter) {
      setAwardsFilter(onlyAwardsSwitch, filterKey, checkedValues)
    } else {
      onSetFilter(filterKey, checkedValues)
    }
  }, [
    checkedValues,
    filterKey,
    isBrandFilter,
    isAwardFilter,
    onSetFilter,
    onlyAwardsSwitch,
    onlyOwnBrandsSwitch,
    setBrandsFilter,
    setAwardsFilter,
  ])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value)
  }

  /**
   * Checking if the user clicked on the input field or the clear icon. If the later
   * was clicked, we reset the searchString
   * @param item
   */
  const checkClear = (item: any) => {
    const clickedElement = item.domEvent.target
    if (!(clickedElement instanceof HTMLInputElement)) {
      setSearchString('')
    }
  }

  const handleToggleValue = (item: { key: string | number }) => {
    // Ignore clicks on unrelated items
    const isFilterKey = aggregation.filterValues.some(
      (filterValue) => filterValue.value === item.key
    )

    if (!isFilterKey) {
      // if the user clicked on the first element, which is a search box, we might
      // need to clear the searchValue
      if (item.key === 'item_0') {
        checkClear(item)
      }
      return
    }
    setCheckedValues(toggleStringInArray(checkedValues, item.key.toString()))
    setOnlyOwnBrandsSwitch(false)
    setOnlyAwardsSwitch(false)
  }

  return (
    <LYSMenu onClick={handleToggleValue} className={style.filterMenu}>
      <LYSInput
        // Auto focus search input when opening dropdown
        ref={(input) => input && input.focus()}
        allowClear
        size={'small'}
        type={'search'}
        placeholder={t('filter.searchOptions')}
        value={searchString}
        onChange={handleSearchChange}
        className={style.extraMargin}
      />
      <LYSMenu.ItemGroup className={style.menuItems}>
        {searchedFilterValues.map((filterValue) => (
          <LYSMenu.Item
            key={filterValue.value}
            className={style.filterListItem}
          >
            <LYSCheckbox
              checked={checkedValues.includes(filterValue.value)}
              className={style.checkbox}
            >
              <span>{filterValue.value}</span>{' '}
              <LYSTypography.Text type={'secondary'}>
                ({filterValue.count})
              </LYSTypography.Text>
            </LYSCheckbox>
          </LYSMenu.Item>
        ))}
      </LYSMenu.ItemGroup>
      <LYSMenu.Divider />
      <LYSButton
        block={true}
        onClick={handleSetFilter}
        size="small"
        type="primary"
        disabled={checkedValues.length < 1}
        className={style.extraMargin}
      >
        {t('filter.save')}
      </LYSButton>
    </LYSMenu>
  )
}

export default SimpleFilter
