import React from 'react'
import { LYSEmpty } from '@core/components/Primitives'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'

export interface NoProductsStateProps {
  className?: string
}

export const NoProductsState: React.FC<NoProductsStateProps> = ({
  className,
}) => {
  const { t } = useTranslation()
  const { query, productCollection, isLoading } =
    useProductCollectionContext() || {}

  const filter = query?.filter

  const areFiltersActive = !!filter && !!Object.keys(filter).length

  const message = areFiltersActive
    ? t('filter.noMatchText')
    : t('searchResult.notFound.product.titleText')

  return !(productCollection && productCollection.total && !isLoading) ? (
    <>
      <LYSEmpty
        image={config.assets.noResultsImage}
        description={message}
        className={className}
      />
    </>
  ) : null
}
