import React, { useCallback, useMemo, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { IProductsQuery } from '@core/api/Products/types'
import {
  LYSButton,
  LYSDropdown,
  LYSIcon,
  LYSMenu,
  LYSSwitch,
} from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery, {
  extractUrlValues,
} from '../../useProductCollectionQuery'
import style from '../SimpleFilter/index.module.less'

export interface SimpleFilterProps {
  filterKey: string
  query: IProductsQuery
  onSetFilter: (key: string, value: string[]) => void
  isBrandFilter?: boolean
}

const InStockFilter: React.FC<SimpleFilterProps> = ({ query }) => {
  const { t } = useTranslation()
  const { onlyInStockFilterActive, toggleOnlyInStock } =
    useProductCollectionQuery()
  const urlValues = extractUrlValues('onlyInStock', query.filter)

  const [isOpen, setOpen] = useState<boolean>(false)
  const [onlyInStockSwitch, setOnlyInStockSwitch] = useState<boolean>(
    onlyInStockFilterActive
  )

  const isActive = !!urlValues.length

  const toggleOnlyInStockSwitch = useCallback(() => {
    setOnlyInStockSwitch(!onlyInStockSwitch)
  }, [onlyInStockSwitch, setOnlyInStockSwitch])

  const handleSetFilter = useCallback(() => {
    toggleOnlyInStock()
    setOpen(false)
  }, [toggleOnlyInStock, setOpen])

  const handleToggleValue = useCallback(() => {
    setOnlyInStockSwitch(!onlyInStockSwitch)
  }, [setOnlyInStockSwitch, onlyInStockSwitch])

  const filterDetail = useMemo(
    () => (
      <LYSMenu onClick={handleToggleValue}>
        <div key={'inStock'} className={style.inStockSwitchDiv}>
          <LYSSwitch
            onChange={toggleOnlyInStockSwitch}
            checked={onlyInStockSwitch}
            className={style.inStockSwitch}
          />
          <label>{t`filter.onlyInStock`}</label>
        </div>

        <LYSMenu.Divider />

        <LYSMenu.Item className={style.removeHoverEffect}>
          <LYSButton
            type="primary"
            block={true}
            onClick={handleSetFilter}
            size={'small'}
          >
            {t('filter.save')}
          </LYSButton>
        </LYSMenu.Item>
      </LYSMenu>
    ),
    [
      onlyInStockSwitch,
      handleSetFilter,
      handleToggleValue,
      t,
      toggleOnlyInStockSwitch,
    ]
  )

  return (
    <LYSDropdown
      onVisibleChange={setOpen}
      visible={isOpen}
      overlay={filterDetail}
      trigger={['click']}
    >
      <LYSButton
        type={isActive ? 'primary' : undefined}
        ghost={isActive ? true : undefined}
        className={style.button}
        size={'small'}
      >
        {t(`filter.labels.inStock`)} <LYSIcon component={DownOutlined} />
      </LYSButton>
    </LYSDropdown>
  )
}

export default InStockFilter
