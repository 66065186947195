import { useCallback } from 'react'
import cn from 'classnames'
import { CloseOutlined } from '@ant-design/icons'
import {
  IProductsQueryFilter,
  PRICE_KEY,
  HAS_PRICE_KEY,
  OWN_BRANDS_KEY,
  IN_STOCK_KEY,
  HAS_AWARDS_KEY,
  ONLY_PURCHASED_KEY,
  ON_PRODUCT_LIST_KEY,
  ONLY_RECENTLY_PURCHASED_KEY,
} from '@core/api/Products/types'
import { LYSIcon, LYSTag } from '@core/components/Primitives'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'
import { useTranslation } from '@core/i18n/i18n'
import { formatPrice } from '@core/utils/i18n'
import { useServices } from '@core/utils/ioc'
import style from './index.module.less'
import config from '@core/config/config'

const BLACKLISTED_ACTIVE_FILTER_KEYS: string[] = []

const returnArrayWithoutValue = (array: string[], valueToRemove: string) =>
  array.filter((value) => value !== valueToRemove)

const ProductCollectionActiveTags: React.FC = () => {
  const { t } = useTranslation()
  const {
    query,
    setTermFilter,
    removeFilter,
    removeKey,
    setBrandsFilter,
    setAwardsFilter,
    toggleOnlyInStock,
    toggleOnlyPurchasedProducts,
    toggleOnlyRecentlyPurchasedProducts,
    toggleOnProductListProducts,
  } = useProductCollectionQuery()
  const attributeFilters = query.filter || {}
  const attributeFilterKeys = Object.keys(attributeFilters).filter(
    (key) => !BLACKLISTED_ACTIVE_FILTER_KEYS.includes(key)
  )
  const ownBrandsFilterSet = `${attributeFilters[OWN_BRANDS_KEY]}` === '1'
  const hasAwardsFilterSet = `${attributeFilters[HAS_AWARDS_KEY]}` === '1'
  const inStockFilterSet = `${attributeFilters[IN_STOCK_KEY]}` === '1'
  const showOnlyPurchasedProductsFilterSet =
    `${attributeFilters[ONLY_PURCHASED_KEY]}` === '1'
  const showOnlyRecentlyPurchasedProductsFilterSet =
    `${attributeFilters[ONLY_RECENTLY_PURCHASED_KEY]}` === '1'
  const showOnlyProductsFromProductListsFilterSet =
    `${attributeFilters[ON_PRODUCT_LIST_KEY]}` === '1'
  const onlyProductsWithPrice = `${attributeFilters[HAS_PRICE_KEY]}` === '1'

  const isRangeQuery = (filter: any): filter is IProductsQueryFilter =>
    !!(filter.gte && filter.lte)

  const { preferences } = useServices()

  const removePriceFilter = useCallback(
    (filterKey: string) => {
      removeFilter(filterKey)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preferences, removeFilter]
  )

  return attributeFilterKeys.length ? (
    <div className={style.tags}>
      <LYSTag
        className={cn(style.closeAll, style.tag)}
        onClick={() => removeKey('filter')}
      >
        {t('product.productCollection.deleteFiltersText')}
        <LYSIcon component={CloseOutlined} />
      </LYSTag>

      {ownBrandsFilterSet && (
        <LYSTag
          key={OWN_BRANDS_KEY}
          onClick={() => setBrandsFilter(false, 'brand', [])}
          className={style.tag}
        >
          {t('filter.onlyOwnBrands')} <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {onlyProductsWithPrice && (
        <LYSTag
          key={HAS_PRICE_KEY}
          onClick={() => setBrandsFilter(false, 'brand', [])}
          className={style.tag}
        >
          {t`filter.onlyHasPrice`} <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {hasAwardsFilterSet && (
        <LYSTag
          key={config.features.filters.awardsFilter.filterKey}
          onClick={() =>
            setAwardsFilter(
              false,
              config.features.filters.awardsFilter.filterKey,
              []
            )
          }
          className={style.tag}
        >
          {t(`filter.${config.features.filters.awardsFilter.filterKey}`)}
          <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {inStockFilterSet && (
        <LYSTag
          key={IN_STOCK_KEY}
          onClick={toggleOnlyInStock}
          className={style.tag}
        >
          {t('filter.onlyInStock')} <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {showOnlyPurchasedProductsFilterSet && (
        <LYSTag
          key={ONLY_PURCHASED_KEY}
          onClick={toggleOnlyPurchasedProducts}
          className={style.tag}
        >
          {t('filter.onlyPurchasedProducts')}{' '}
          <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {showOnlyRecentlyPurchasedProductsFilterSet && (
        <LYSTag
          key={ONLY_RECENTLY_PURCHASED_KEY}
          onClick={toggleOnlyRecentlyPurchasedProducts}
          className={style.tag}
        >
          {t('filter.onlyRecentlyPurchasedProducts')}{' '}
          <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {showOnlyProductsFromProductListsFilterSet && (
        <LYSTag
          key={ON_PRODUCT_LIST_KEY}
          onClick={toggleOnProductListProducts}
          className={style.tag}
        >
          {t('filter.onProductList')} <LYSIcon component={CloseOutlined} />
        </LYSTag>
      )}

      {attributeFilterKeys.map((filterKey) => {
        const currentFilter = attributeFilters[filterKey]

        if (isRangeQuery(currentFilter)) {
          // currently we don't have enough info to handle other filters of range type
          if (filterKey !== PRICE_KEY) return

          return (
            <LYSTag
              key={filterKey}
              onClick={() => removePriceFilter(filterKey)}
              className={style.tag}
            >
              {formatPrice(currentFilter.gte)} -{' '}
              {formatPrice(currentFilter.lte)}
              <LYSIcon component={CloseOutlined} />
            </LYSTag>
          )
        } else {
          // only simple filters are currently supported
          if (!Array.isArray(currentFilter)) return

          return currentFilter.map((filterValue) => (
            <LYSTag
              key={filterValue}
              onClick={() =>
                setTermFilter(
                  filterKey,
                  returnArrayWithoutValue(currentFilter, filterValue)
                )
              }
              className={style.tag}
            >
              {filterValue} <LYSIcon component={CloseOutlined} />
            </LYSTag>
          ))
        }
      })}
    </div>
  ) : null
}

export default ProductCollectionActiveTags
