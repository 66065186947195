import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import xor from 'lodash/xor'
import { DownOutlined } from '@ant-design/icons'
import {
  IProductAggregationTerm,
  IProductsQuery,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCheckbox,
  LYSCol,
  LYSDropdown,
  LYSIcon,
  LYSInput,
  LYSMenu,
  LYSSwitch,
  LYSTypography,
} from '@core/components/Primitives'
import {
  useSearchedFilterValues,
  useSortedFilterValues,
} from '@core/components/ProductCollection/Filters/SimpleFilter/sortAndSearch'
import config from '@core/config/config'
import productFiltersConfig, {
  ProductFilterAttributeConfig,
  ProductFilterAttributeSorting,
} from '@core/config/productFilters'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery, {
  extractUrlValues,
} from '../../useProductCollectionQuery'
import style from './index.module.less'

const toggleStringInArray = (array: string[], value: string) =>
  xor(array, [value])

const defaultAttributeConfig: ProductFilterAttributeConfig = {
  sorting: ProductFilterAttributeSorting.Relevance,
}

const useFilterConfig = (attributeKey: string): ProductFilterAttributeConfig =>
  useMemo(
    () =>
      productFiltersConfig.attributes[attributeKey] || defaultAttributeConfig,
    [attributeKey]
  )

export interface SimpleFilterProps {
  aggregation: IProductAggregationTerm
  filterKey: string
  query: IProductsQuery
  label: string
  onSetFilter: (key: string, value: string[]) => void
  isBrandFilter?: boolean
  isAwardFilter?: boolean
}

const SimpleFilter: React.FC<SimpleFilterProps> = ({
  aggregation,
  filterKey,
  query,
  onSetFilter,
  label,
  isBrandFilter,
  isAwardFilter,
}) => {
  const { t } = useTranslation()
  const {
    onlyOwnBrandsFilterActive,
    onlyAwardsFilterActive,
    setBrandsFilter,
    setAwardsFilter,
  } = useProductCollectionQuery()

  const [onlyOwnBrandsSwitch, setOnlyOwnBrandsSwitch] = useState(
    onlyOwnBrandsFilterActive
  )
  const [onlyAwardsSwitch, setOnlyAwardsSwitch] = useState(
    onlyAwardsFilterActive
  )

  const [isOpen, setOpen] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const urlValues = extractUrlValues(filterKey, query.filter)
  const attributeConfig = useFilterConfig(filterKey)
  const [checkedValues, setCheckedValues] = useState<string[]>(urlValues)
  const sortedFilterValues = useSortedFilterValues(
    aggregation.filterValues,
    attributeConfig.sorting
  )
  const searchedFilterValues = useSearchedFilterValues(
    sortedFilterValues,
    searchString
  )

  const isActive = !!urlValues.length

  // we need the filter to refresh it's state if the filter was removed outside of component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCheckedValues(urlValues), [JSON.stringify(urlValues)])

  const handleSetFilter = useCallback(() => {
    if (isBrandFilter) {
      setBrandsFilter(onlyOwnBrandsSwitch, filterKey, checkedValues)
    } else if (isAwardFilter) {
      setAwardsFilter(onlyAwardsSwitch, filterKey, checkedValues)
    } else {
      onSetFilter(filterKey, checkedValues)
    }
    setOpen(false)
  }, [
    checkedValues,
    filterKey,
    isBrandFilter,
    isAwardFilter,
    onSetFilter,
    onlyAwardsSwitch,
    onlyOwnBrandsSwitch,
    setBrandsFilter,
    setAwardsFilter,
  ])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value)
  }

  /**
   * Checking if the user clicked on the input field or the clear icon. If the later
   * was clicked, we reset the searchString
   * @param item
   */
  const checkClear = (item: any) => {
    const clickedElement = item.domEvent.target
    if (!(clickedElement instanceof HTMLInputElement)) {
      setSearchString('')
    }
  }

  const toggleOnlyOwnBrandsSwitch = useCallback(() => {
    setOnlyOwnBrandsSwitch(!onlyOwnBrandsSwitch)
    if (!onlyOwnBrandsSwitch) {
      setCheckedValues([])
    }
  }, [onlyOwnBrandsSwitch])

  const toggleAwardSwitch = useCallback(() => {
    setOnlyAwardsSwitch(!onlyAwardsSwitch)
    if (!onlyAwardsSwitch) {
      setCheckedValues([])
    }
  }, [onlyAwardsSwitch])

  const handleToggleValue = (item: { key: string | number }) => {
    // Ignore clicks on unrelated items
    const isFilterKey = aggregation.filterValues.some(
      (filterValue) => filterValue.value === item.key
    )

    if (!isFilterKey) {
      // if the user clicked on the first element, which is a search box, we might
      // need to clear the searchValue
      if (item.key === 'item_0') {
        checkClear(item)
      }
      return
    }
    setCheckedValues(toggleStringInArray(checkedValues, item.key.toString()))
    setOnlyOwnBrandsSwitch(false)
    setOnlyAwardsSwitch(false)
  }

  const awardIcon =
    config.features.filters.awardsFilter.active &&
    config.features.filters.awardsFilter.filterKey === filterKey &&
    config.features.filters.awardsFilter.icon ? (
      <img
        className={style.awardsLogo}
        src={config.features.filters.awardsFilter.icon}
        alt={config.features.filters.awardsFilter.filterKey}
      />
    ) : null

  const filterDetail = (
    <LYSMenu onClick={handleToggleValue}>
      <LYSMenu.Item className={style.removeHoverEffect}>
        <LYSInput
          // Auto focus search input when opening dropdown
          ref={(input) => input && input.focus()}
          allowClear
          size={'small'}
          type={'search'}
          placeholder={t('filter.searchOptions')}
          value={searchString}
          onChange={handleSearchChange}
        />
      </LYSMenu.Item>
      <LYSMenu.ItemGroup className={style.menuItems}>
        {searchedFilterValues.map((filterValue) => (
          <LYSMenu.Item key={filterValue.value}>
            <LYSCheckbox
              checked={checkedValues.includes(filterValue.value)}
              className={style.checkbox}
            >
              <span>{filterValue.value}</span>{' '}
              <LYSTypography.Text type={'secondary'}>
                ({filterValue.count})
              </LYSTypography.Text>
            </LYSCheckbox>
          </LYSMenu.Item>
        ))}
      </LYSMenu.ItemGroup>
      <LYSMenu.Divider />
      {config.features.filters.ownBrandsFilter && isBrandFilter && (
        <LYSCol xs={24}>
          <LYSSwitch
            onChange={toggleOnlyOwnBrandsSwitch}
            checked={onlyOwnBrandsSwitch}
            className={style.onlyOwnBrandsSwitch}
          />
          <label>{t`filter.onlyOwnBrands`}</label>
        </LYSCol>
      )}
      {config.features.filters.awardsFilter.active && isAwardFilter && (
        <LYSCol xs={24}>
          <LYSSwitch
            onChange={toggleAwardSwitch}
            checked={onlyAwardsSwitch}
            className={style.onlyOwnBrandsSwitch}
          />
          <label>
            {t(`filter.${config.features.filters.awardsFilter.filterKey}`)}
          </label>
        </LYSCol>
      )}
      <LYSMenu.Item className={style.removeHoverEffect}>
        <LYSButton
          type="primary"
          block={true}
          onClick={handleSetFilter}
          size={'small'}
        >
          {t('filter.save')}
        </LYSButton>
      </LYSMenu.Item>
    </LYSMenu>
  )

  return (
    <LYSDropdown
      onVisibleChange={setOpen}
      visible={isOpen}
      overlay={filterDetail}
      trigger={['click']}
    >
      <LYSButton
        type={isActive ? 'primary' : undefined}
        ghost={isActive ? true : undefined}
        className={style.button}
        size={'small'}
      >
        {awardIcon}
        {label} <LYSIcon component={DownOutlined} />
      </LYSButton>
    </LYSDropdown>
  )
}

export default SimpleFilter
