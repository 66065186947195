import { useMemo } from 'react'
import { IProductAggregationFilterValue } from '@core/api/Products/types'
import { ProductFilterAttributeSorting } from '@core/config/productFilters'
import { safeParseNumber } from '@core/utils/numbers'

const byNumericValue = (
  left: IProductAggregationFilterValue,
  right: IProductAggregationFilterValue
): number => {
  const leftNumber = safeParseNumber(left.value)
  const rightNumber = safeParseNumber(right.value)
  return leftNumber - rightNumber
}

const byAlphabeticalValue = (
  left: IProductAggregationFilterValue,
  right: IProductAggregationFilterValue
): number => {
  const leftValue = left.value.toLowerCase()
  const rightValue = right.value.toLowerCase()

  if (leftValue < rightValue) return -1
  if (leftValue > rightValue) return 1
  return 0
}

const byRelevance = (
  left: IProductAggregationFilterValue,
  right: IProductAggregationFilterValue
): number => {
  return right.count - left.count
}

export const sortFilterValues = (
  filterValues: IProductAggregationFilterValue[],
  sorting: ProductFilterAttributeSorting
) => {
  const result = filterValues.slice()
  switch (sorting) {
    case ProductFilterAttributeSorting.Relevance:
      result.sort(byRelevance)
      break
    case ProductFilterAttributeSorting.Alphabetical:
      result.sort(byAlphabeticalValue)
      break
    case ProductFilterAttributeSorting.Numeric:
      result.sort(byNumericValue)
      break
    default:
      throw new Error(`Unexpected filter attribute sorting: ${sorting}`)
  }
  return result
}

export const useSortedFilterValues = (
  filterValues: IProductAggregationFilterValue[],
  sorting: ProductFilterAttributeSorting
) =>
  useMemo(
    () => sortFilterValues(filterValues, sorting),
    [filterValues, sorting]
  )

export const searchFilterValues = (
  filterValues: IProductAggregationFilterValue[],
  searchText: string
) => {
  return filterValues.filter(
    (filterValue) =>
      filterValue.value.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
  )
}

export const useSearchedFilterValues = (
  filterValues: IProductAggregationFilterValue[],
  searchText: string
) =>
  useMemo(
    () => searchFilterValues(filterValues, searchText),
    [filterValues, searchText]
  )
