import React from 'react'
import { LYSCheckbox } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../../useProductCollectionQuery'
import style from '../index.module.less'

export const OnlyPurchasedProductsFilter: React.FC = () => {
  const { t } = useTranslation()
  const { onlyPurchasedProductsActive, toggleOnlyPurchasedProducts } =
    useProductCollectionQuery()

  return (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleOnlyPurchasedProducts}
        checked={onlyPurchasedProductsActive}
      >
        <label data-testid={'only-purchased-products-toggle'}>
          {t('filter.onlyPurchasedProducts')}
        </label>
      </LYSCheckbox>
    </span>
  )
}

export const OnlyRecentlyPurchasedProductsFilter: React.FC = () => {
  const { t } = useTranslation()
  const {
    onlyRecentlyPurchasedProductsActive,
    toggleOnlyRecentlyPurchasedProducts,
  } = useProductCollectionQuery()

  return (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleOnlyRecentlyPurchasedProducts}
        checked={onlyRecentlyPurchasedProductsActive}
      >
        <label data-testid={'only-recently-purchased-products-toggle'}>
          {t('filter.onlyRecentlyPurchasedProducts')}
        </label>
      </LYSCheckbox>
    </span>
  )
}
