import React from 'react'
import { LYSSwitch } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../useProductCollectionQuery'
import style from '../OnlyPurchasedFilters/index.module.less'

const OnProductListFilter: React.FC = () => {
  const { t } = useTranslation()
  const {
    onlyOnProductListActive,
    toggleOnProductListProducts,
  } = useProductCollectionQuery()

  return (
    <span className={style.onlyBoughtContainer}>
       <LYSSwitch
         onChange={toggleOnProductListProducts}
         checked={onlyOnProductListActive}
       />
       <label data-testid={'only-on-product-list-products-toggle'}>
         {t('filter.onProductList')}
       </label>
     </span>
  )
}

export default OnProductListFilter