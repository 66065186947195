import React, { useCallback } from 'react'
import { LYSCheckbox } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../../useProductCollectionQuery'
import style from '../index.module.less'

const OnlyOwnBrandsFilter: React.FC = () => {
  const { t } = useTranslation()

  const { onlyOwnBrandsFilterActive, setBrandsFilter } =
    useProductCollectionQuery()

  const toggleOnlyOwnBrandsSwitch = useCallback(() => {
    setBrandsFilter(!onlyOwnBrandsFilterActive, 'brand', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyOwnBrandsFilterActive])

  return (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleOnlyOwnBrandsSwitch}
        checked={onlyOwnBrandsFilterActive}
        className={style.onlyOwnBrandsSwitch}
      >
        <label>{t`filter.onlyOwnBrands`}</label>
      </LYSCheckbox>
    </span>
  )
}

export default OnlyOwnBrandsFilter
