import * as React from 'react'
import { useMemo } from 'react'
import {
  getImageFromVariantOrPlaceholder,
  IProductSearch,
} from '@core/api/Products/types'
import {
  LYSCard,
  LYSCol,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import ProductPrice from '@core/components/shared/ProductPrice'
import StockInformation from '@core/components/shared/StockInformation'
import { StockInfoLocation } from '@core/components/shared/StockInformation/types'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import getSku from '@core/utils/models/getSku'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import cn from 'classnames'
import { RenderProp, template, Slot } from '@core/utils/templates'
import style from './index.module.less'
import MobileProductActionElements from '@core/components/shared/ProductCard/MobileProductActionElements'
import { usePageContext } from '@core/utils/pages/PageContext'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'

interface OwnProps {
  product: IProductSearch
}

interface MobileProductCollectionItemSlots {
  image?: RenderProp<void>
  descriptionHolder?: RenderProp<void>
  title?: RenderProp<void>
  description?: RenderProp<void>
  price?: RenderProp<void>
  stockInformation?: RenderProp<void>
  actionElements?: RenderProp<void>
}

const MobileProductCollectionItem = template<
  OwnProps,
  MobileProductCollectionItemSlots
>(({ product, slots }) => {
  const { productContextService } = useServices()
  const {
    props: { isMobile },
  } = usePageContext()
  const image = getImageFromVariantOrPlaceholder(product.mainVariant)
  const { t } = useTranslation()
  const productContext = useMemo(
    () =>
      productContextService.getProductSearchContext(
        product,
        product.mainVariant
      ),
    [productContextService, product]
  )
  const { isPendingPriceRequest } = usePriceRequests(product)
  return (
    <ProductContextProvider productContext={productContext}>
      <LYSCard size="small" withBoxShadow={isMobile ? false : true}>
        <LYSRow justify="space-between" align="middle">
          <LYSCol xs={20}>
            <LYSRow className={style.productInfoWrap}>
              <LYSCol xs={8} className={style.productImageWrap}>
                <Slot render={slots?.image}>
                  <ProductDetailLink
                    product={product}
                    slug={product.mainVariant.slug}
                    id={product.mainVariant.id}
                  >
                    <div className={style.imageHolder}>
                      <img
                        src={image.url}
                        className={style.image}
                        alt={product.name}
                        title={product.name}
                        loading="lazy"
                        width={75}
                        height={75}
                      />
                    </div>
                  </ProductDetailLink>
                </Slot>
              </LYSCol>
              <LYSCol xs={16} className={style.productDescriptionWrap}>
                <Slot render={slots?.descriptionHolder}>
                  <LYSCol xs={24}>
                    <Slot render={slots?.title}>
                      <ProductDetailLink
                        product={product}
                        slug={product.mainVariant.slug}
                        id={product.mainVariant.id}
                      >
                        <LYSTypography.Title
                          level={4}
                          className={style.productName}
                        >
                          {product.name}
                        </LYSTypography.Title>
                      </ProductDetailLink>
                      {getSku(product, product.mainVariant)}
                    </Slot>
                  </LYSCol>
                  <LYSCol xs={24} className={style.productPrice}>
                    <Slot render={slots?.price}>
                      {product.mainVariant.packagingUnits[0].price && (
                        <ProductPrice
                          price={product.mainVariant.packagingUnits[0].price}
                          unitPrice={
                            product.mainVariant.packagingUnits[0].unitPrice
                          }
                          strikePrice={
                            product.mainVariant.packagingUnits[0]
                              .strikeThroughPrice?.price
                          }
                          showDiscountHint={true}
                        />
                      )}
                      {productContext.rules.displayNoPriceHint && (
                        <div
                          className={cn(
                            style.hintContainer,
                            isPendingPriceRequest && style.product_open
                          )}
                        >
                          {isPendingPriceRequest
                            ? t`priceRequest.status.pending`
                            : t('product.hint.noPrice')}
                        </div>
                      )}
                    </Slot>
                  </LYSCol>
                </Slot>
              </LYSCol>
            </LYSRow>
          </LYSCol>
          <LYSCol xs={4} className={style.inputWrap}>
            <Slot render={slots?.stockInformation}>
              {!productContext.rules.hideStockInformation && (
                <StockInformation
                  stock={productContext.stock}
                  location={StockInfoLocation.ProductCollectionItem}
                />
              )}
            </Slot>
            <Slot render={slots?.actionElements}>
              <MobileProductActionElements hidePriceRequestPendingInfo />
            </Slot>
          </LYSCol>
        </LYSRow>
      </LYSCard>
    </ProductContextProvider>
  )
})

export default MobileProductCollectionItem
