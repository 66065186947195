import { useEffect, useMemo } from 'react'
import { IProductCollection, IProductsQuery } from '@core/api/Products/types'
import { useServices } from '@core/utils/ioc'
import useGenericFetchFromApi, { IStateType } from './useGenericFetchFromApi'

const useFetchProducts = (
  query?: IProductsQuery,
  disable?: boolean
): [IStateType<IProductCollection>] => {
  const { productQueryService } = useServices()
  const fetchFunction = useMemo(
    () => productQueryService.runProductsQuery.bind(productQueryService),
    [productQueryService]
  )

  // we only want changed reference if the query contents actually changed
  const stringifiedQuery = JSON.stringify(query)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedQuery = useMemo(() => query, [stringifiedQuery])

  const [fetchData, setFetchQuery] = useGenericFetchFromApi<
    IProductsQuery | undefined,
    IProductCollection
  >({
    initialQueryParams: memoizedQuery,
    fetchFunction,
    disable,
  })

  useEffect(() => {
    setFetchQuery(memoizedQuery)
  }, [memoizedQuery, setFetchQuery])

  return [fetchData]
}

export default useFetchProducts
