import { IOrder } from '@core/api/Orders/types'
import { LYSMenu } from '@core/components/Primitives'
import config from '@core/config/config'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import useFetchOrders from '@core/utils/useFetchOrders'
import React from 'react'
import {
  OnlyPurchasedProductsFilter,
  OnlyRecentlyPurchasedProductsFilter,
} from './OnlyPurchasedFilters'
import OnlyWithPriceFilter from './OnlyWithPriceFilter'
import useProductLists from '@core/hooks/productList/useProductLists'
import useChannel from '@core/hooks/channel/useChannel'
import OnProductListFilter from './OnProductListFilter'
import InStockFilter from './InStockFilter'
import { StockInformationTypes } from '@core/api/Channel/types'
import OnlyOwnBrandsFilter from './OnlyOwnBrandsFilter'
import IsAwardsFilter from './IsAwardFilter'
import styles from './index.module.less'
import { RenderProp, Slot, template } from '@core/utils/templates'

export interface TogglesSlots {
  OnlyOwnBrands?: RenderProp<void>
  IsAwards?: RenderProp<void>
  ShowOnlyWithPrice?: RenderProp<void>
  ShowOnlyRecentlyPurchasedProducts?: RenderProp<void>
  OnlyPurchasedProducts?: RenderProp<void>
  ShowOnProductList?: RenderProp<void>
  ShowOnlyInStock?: RenderProp<void>
}

export const Toggles = template<any, TogglesSlots>(({ slots }) => {
  const { isAuthenticated } = useCurrentUser()
  const [ordersRequest] = useFetchOrders()
  const { productLists } = useProductLists()
  const { userChannel } = useChannel()
  const stockInformationType = userChannel?.displayStockMode
  const orders: IOrder[] =
    (ordersRequest.payload && ordersRequest.payload.orders) || []

  const OnlyOwnBrands = config.features.filters.ownBrandsFilter ? (
    <LYSMenu.Item className={styles.filterListItem}>
      <OnlyOwnBrandsFilter />
    </LYSMenu.Item>
  ) : null

  const IsAwards = config.features.filters.awardsFilter.active ? (
    <LYSMenu.Item className={styles.filterListItem}>
      <IsAwardsFilter />
    </LYSMenu.Item>
  ) : null

  const OnlyPurchasedProducts =
    config.features.filters.showOnlyPurchasedProducts &&
    isAuthenticated &&
    orders.length > 0 ? (
      <LYSMenu.Item className={styles.filterListItem}>
        <OnlyPurchasedProductsFilter />
      </LYSMenu.Item>
    ) : null

  const ShowOnlyRecentlyPurchasedProducts =
    config.features.filters.showOnlyRecentlyPurchasedProducts &&
    isAuthenticated &&
    orders.length > 0 ? (
      <LYSMenu.Item className={styles.filterListItem}>
        <OnlyRecentlyPurchasedProductsFilter />
      </LYSMenu.Item>
    ) : null

  const ShowOnProductList =
    config.features.filters.showOnProductListFilter &&
    isAuthenticated &&
    productLists.length > 0 ? (
      <LYSMenu.Item className={styles.filterListItem}>
        <OnProductListFilter />
      </LYSMenu.Item>
    ) : null

  const ShowOnlyWithPrice =
    config.features.filters.showOnlyWithPriceFilter && isAuthenticated ? (
      <LYSMenu.Item className={styles.filterListItem}>
        <OnlyWithPriceFilter />
      </LYSMenu.Item>
    ) : null

  const ShowOnlyInStock =
    stockInformationType !== StockInformationTypes.disabled ? (
      <LYSMenu.Item className={styles.filterListItem}>
        <InStockFilter />
      </LYSMenu.Item>
    ) : null

  return (
    <LYSMenu>
      <Slot render={slots?.OnlyOwnBrands}>{OnlyOwnBrands}</Slot>
      <Slot render={slots?.IsAwards}>{IsAwards}</Slot>
      <Slot render={slots?.ShowOnlyWithPrice}>{ShowOnlyWithPrice}</Slot>
      <Slot render={slots?.ShowOnlyRecentlyPurchasedProducts}>
        {ShowOnlyRecentlyPurchasedProducts}
      </Slot>
      <Slot render={slots?.OnlyPurchasedProducts}>{OnlyPurchasedProducts}</Slot>
      <Slot render={slots?.ShowOnProductList}> {ShowOnProductList}</Slot>
      <Slot render={slots?.ShowOnlyInStock}>{ShowOnlyInStock}</Slot>
    </LYSMenu>
  )
})

export default Toggles
