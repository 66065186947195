import React from 'react'
import { LYSSwitch } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../useProductCollectionQuery'
import style from './index.module.less'

export const OnlyWithPriceFilter: React.FC = () => {
  const { t } = useTranslation()
  const { setOnlyPriceFilter, onlyWithPriceFilterActive } =
    useProductCollectionQuery()

  const handleSetFilter = (value: boolean) => {
    setOnlyPriceFilter(value)
  }

  return (
    <span className={style.onlyWithPriceContainer}>
      <LYSSwitch
        onChange={(value) => handleSetFilter(value)}
        checked={onlyWithPriceFilterActive}
      />
      <label
        data-testid={'only-with-price-toggle'}
      >{t`filter.onlyHasPrice`}</label>
    </span>
  )
}
