import {
  AggregationsTypes,
  IProductAggregation,
  PRICE_KEY,
} from '@core/api/Products/types'

const isValidRangeAggregation = (aggregation: IProductAggregation) => {
  // currently we don't have enough info to handle other filters of range type
  return (
    aggregation.type === AggregationsTypes.RANGE &&
    aggregation.filterKey === PRICE_KEY
  )
}

const isValidTermAggregation = (aggregation: IProductAggregation) => {
  return (
    aggregation.type === AggregationsTypes.TERM &&
    aggregation.filterValues.length > 0
  )
}

export const getValidAggregations = (
  aggregations: IProductAggregation[],
  excludedKeys: string[] = []
) => {
  return aggregations.filter(
    (aggregation) =>
      !excludedKeys.includes(aggregation.filterKey) &&
      (isValidRangeAggregation(aggregation) ||
        isValidTermAggregation(aggregation))
  )
}

export function sortFilterKeyToFirst(filterKey: string) {
  return (a: IProductAggregation, b: IProductAggregation) =>
    a.filterKey === filterKey ? -1 : b.filterKey === filterKey ? 1 : 0
}
