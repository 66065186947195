import React, { useCallback } from 'react'
import { LYSCheckbox } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../../useProductCollectionQuery'
import style from '../index.module.less'

const OnlyWithPriceFilter: React.FC = () => {
  const { t } = useTranslation()
  const { setOnlyPriceFilter, onlyWithPriceFilterActive } =
    useProductCollectionQuery()

  const handleSetFilter = useCallback(() => {
    setOnlyPriceFilter(!onlyWithPriceFilterActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyWithPriceFilterActive])

  return (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={handleSetFilter}
        checked={onlyWithPriceFilterActive}
      >
        <label
          data-testid={'only-with-price-toggle'}
        >{t`filter.onlyHasPrice`}</label>
      </LYSCheckbox>
    </span>
  )
}

export default OnlyWithPriceFilter
