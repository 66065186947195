import React from 'react'
import Link from 'next/link'
import { ISearchTermSuggestions } from '@core/api/Products/types'
import { AvailableSearchTypes } from '@core/components/AutoSuggest/SearchTypeSwitch'
import { LYSCol, LYSTypography } from '@core/components/Primitives'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import styles from './index.module.less'

const cleanUpSearchTermSuggestions = (
  searchTermSuggestions?: ISearchTermSuggestions
) => {
  return (searchTermSuggestions?.suggestions || []).filter(
    (s) => searchTermSuggestions!.searchTerm !== s
  )
}

export interface SearchTermSuggestionsProps {
  className: string
}

interface IProps {
  searchType?: AvailableSearchTypes
}

type Props = IProps & SearchTermSuggestionsProps

const SearchTermSuggestions: React.FC<Props> = ({
  searchType = AvailableSearchTypes.PRODUCT,
  className,
}) => {
  const { t } = useTranslation()

  const { productCollection, showSearchTermSuggestions } =
    useProductCollectionContext() || {}

  const searchTermSuggestions =
    productCollection?.suggestions?.searchTermSuggestions

  const cleanedSuggestions = cleanUpSearchTermSuggestions(searchTermSuggestions)

  if (!cleanedSuggestions.length) {
    return null
  }

  return (
    <>
      {showSearchTermSuggestions && (
        <LYSCol xs={24} className={className}>
          <div>
            <LYSTypography.Text className={styles.hint}>
              {t`search.termSuggestion`}
            </LYSTypography.Text>
            {cleanedSuggestions.map((suggestion) => (
              <Link
                key={suggestion}
                {...routes.searchResult(suggestion, searchType)}
              >
                <a className={styles.suggestion}>
                  <span>{suggestion}</span>
                </a>
              </Link>
            ))}
          </div>
        </LYSCol>
      )}
    </>
  )
}

export default SearchTermSuggestions
