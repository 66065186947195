export enum ProductFilterAttributeSorting {
  // Sort filter values by number of matched documents, descending
  Relevance = 'relevance',
  // Sort filter values by string value, ascending
  Alphabetical = 'alphabetical',
  // Sort filter values by safely parsing the string value, ascending
  Numeric = 'numeric',
}

export interface ProductFilterAttributeConfig {
  sorting: ProductFilterAttributeSorting
}

interface FilterAttributesMap {
  [attributeKey: string]: ProductFilterAttributeConfig | undefined
}

export interface ProductFiltersConfig {
  attributes: FilterAttributesMap
}

const productFiltersConfig: ProductFiltersConfig = {
  attributes: {},
}

export default productFiltersConfig
