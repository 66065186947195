import React, { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {
  AggregationsTypes,
  IProductAggregationRange,
  IProductAggregationTerm,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCard,
  LYSCol,
  LYSDivider,
  LYSLoading,
  LYSMenu,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import { RenderProp, Slot, template } from '@core/utils/templates'
import useFetchAggregations from '@core/utils/useFetchAggregations'
import PriceFilter from './PriceFilter'
import SimpleFilter from './SimpleFilter'
import { getValidAggregations } from '../Filters/helper'
import style from './index.module.less'
import config from '@core/config/config'
import Toggles from './Toggles'

const COLLAPSED_FILTER_COUNT = 6

export interface ProductCollectionFiltersProps {
  showCategoryFilter?: boolean
}

export interface ProductCollectionFilersSlots {
  loading?: RenderProp<void>
  expandButton?: RenderProp<{ filtersExpanded: boolean }>
  filterContainer?: RenderProp<void>
  toggles: RenderProp<void>
}

export interface ProductCollectionFiltersComponents {
  Toggles: typeof Toggles
  PriceFilter: typeof PriceFilter
  SimpleFilter: typeof SimpleFilter
}

const ProductCollectionFilters = template<
  ProductCollectionFiltersProps,
  ProductCollectionFilersSlots,
  ProductCollectionFiltersComponents
>(({ slots, getComponents }) => {
  const { t } = useTranslation()

  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

  const { queryToFetch, brandShortId } = useProductCollectionContext() || {}

  const filterKeysToHide = brandShortId ? ['brand'] : []

  if (!queryToFetch) return null

  const { aggregations, isLoading } = useFetchAggregations(queryToFetch)

  const filters = getValidAggregations(aggregations || [], filterKeysToHide)

  const { setTermFilter } = useProductCollectionQuery()

  const [priceFilter] = useMemo(
    () => filters.filter((filter) => filter.filterKey === 'price'),
    [filters]
  )
  const [brandsFilter] = useMemo(
    () => filters.filter((filter) => filter.filterKey === 'brand'),
    [filters]
  )

  const [awardsFilter] = useMemo(
    () =>
      filters.filter(
        (filter) =>
          filter.filterKey === config.features.filters.awardsFilter.filterKey
      ),
    [filters]
  )

  const filtersWithoutPriceAndBrandAndAwards = useMemo(
    () =>
      filters.filter(
        (filter) =>
          filter.filterKey !== 'brand' &&
          filter.filterKey !== 'price' &&
          filter.filterKey !== config.features.filters.awardsFilter.filterKey
      ),
    [filters]
  )

  const visibleFilters = useMemo(
    () =>
      filtersWithoutPriceAndBrandAndAwards.slice(
        0,
        filtersExpanded
          ? filtersWithoutPriceAndBrandAndAwards.length
          : COLLAPSED_FILTER_COUNT
      ),
    [filtersWithoutPriceAndBrandAndAwards, filtersExpanded]
  )

  const showExpandButton = filters.length > COLLAPSED_FILTER_COUNT

  const handleToggleExpanded = () => setFiltersExpanded(!filtersExpanded)

  const components = getComponents({
    Toggles,
    PriceFilter,
    SimpleFilter,
  })

  if (isLoading)
    return (
      <Slot render={slots?.loading}>
        <LYSCard bordered={false} className={style.box} size="small">
          <LYSRow align="middle" gutter="md">
            <div className={style.loadingSpinnerHolder}>
              <LYSLoading />
            </div>
          </LYSRow>
        </LYSCard>
      </Slot>
    )

  const awardIcon =
    config.features.filters.awardsFilter.active &&
    config.features.filters.awardsFilter.filterKey ===
      awardsFilter?.filterKey &&
    config.features.filters.awardsFilter.icon ? (
      <img
        className={style.awardsLogo}
        src={config.features.filters.awardsFilter.icon}
        alt={config.features.filters.awardsFilter.filterKey}
      />
    ) : null

  return (
    <>
      <LYSCard
        bordered={true}
        className={style.box}
        size="small"
        data-testid="product-collection-filters-card"
      >
        <LYSCol
          xs={24}
          data-testid="product-collection-filters-card"
          className={style.filtersCard}
        >
          <LYSTypography.Text
            className={style.filterHeading}
            visualAppearance="h4"
          >
            {t('filter.headline')}
          </LYSTypography.Text>
          <Slot render={slots?.toggles}>
            <components.Toggles />
            <LYSDivider />
          </Slot>
          <Slot render={slots?.filterContainer}>
            <LYSMenu
              className={style.menu}
              mode="inline"
              data-testid="price-filter-open-btn"
            >
              {!isEmpty(priceFilter) && (
                <LYSMenu.SubMenu
                  key={priceFilter.filterKey}
                  title={t('filter.labels.price')}
                >
                  <components.PriceFilter
                    query={queryToFetch}
                    aggregation={priceFilter as IProductAggregationRange}
                  />
                </LYSMenu.SubMenu>
              )}
              {!isEmpty(brandsFilter) && (
                <LYSMenu.SubMenu
                  key={brandsFilter.filterKey}
                  title={t(
                    `filter.labels.${brandsFilter.filterLabel}`,
                    brandsFilter.filterLabel
                  )}
                >
                  <components.SimpleFilter
                    key={brandsFilter.filterKey}
                    query={queryToFetch}
                    onSetFilter={setTermFilter}
                    filterKey={brandsFilter.filterKey}
                    aggregation={brandsFilter as IProductAggregationTerm}
                    isBrandFilter
                  />
                </LYSMenu.SubMenu>
              )}
              {!isEmpty(awardsFilter) && (
                <LYSMenu.SubMenu
                  key={awardsFilter.filterKey}
                  icon={awardIcon}
                  title={t(
                    `filter.labels.${awardsFilter.filterLabel}`,
                    awardsFilter.filterLabel
                  )}
                >
                  <components.SimpleFilter
                    key={awardsFilter.filterKey}
                    query={queryToFetch}
                    onSetFilter={setTermFilter}
                    filterKey={awardsFilter.filterKey}
                    aggregation={awardsFilter as IProductAggregationTerm}
                    isAwardFilter
                  />
                </LYSMenu.SubMenu>
              )}

              {visibleFilters.map((filter) => {
                if (filter.filterKey === 'attributes.114') return null
                return (
                  <>
                    {filter.type === AggregationsTypes.RANGE ? (
                      <components.PriceFilter
                        query={queryToFetch}
                        aggregation={filter}
                        key={filter.filterKey}
                      />
                    ) : (
                      <LYSMenu.SubMenu
                        title={t(
                          `filter.labels.${filter.filterLabel}`,
                          filter.filterLabel
                        )}
                      >
                        <components.SimpleFilter
                          query={queryToFetch}
                          onSetFilter={setTermFilter}
                          filterKey={filter.filterKey}
                          aggregation={filter}
                        />
                      </LYSMenu.SubMenu>
                    )}
                  </>
                )
              })}
            </LYSMenu>
          </Slot>
          {showExpandButton && (
            <Slot render={slots?.expandButton} props={{ filtersExpanded }}>
              <LYSButton
                onClick={handleToggleExpanded}
                type="link"
                icon={
                  filtersExpanded ? (
                    <MinusOutlined rev={undefined} />
                  ) : (
                    <PlusOutlined rev={undefined} />
                  )
                }
              >
                {t(
                  filtersExpanded ? 'filter.lessFilters' : 'filter.moreFilters'
                )}
              </LYSButton>
            </Slot>
          )}
        </LYSCol>
      </LYSCard>
    </>
  )
})

export default ProductCollectionFilters
