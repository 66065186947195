import { useCallback, useEffect, useReducer, useState } from 'react'

const FETCH_INIT = 'FETCH_INIT'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_FAILURE = 'FETCH_FAILURE'
const MARK_FIRST_RUN = 'MARK_FIRST_RUN'

interface IFetchHook<Q, P> {
  initialQueryParams: Q
  fetchFunction: (queryParams: Q) => Promise<P>
  disable?: boolean
}

export interface IStateType<P> {
  isLoading: boolean
  isError: boolean
  payload?: P
  isInitialRun: boolean
}

interface ActionType<P> {
  type:
    | typeof FETCH_INIT
    | typeof FETCH_SUCCESS
    | typeof FETCH_FAILURE
    | typeof MARK_FIRST_RUN
  payload?: P
}

const createFetchDataReducer =
  <P>() =>
  (state: IStateType<P>, action: ActionType<P>): IStateType<P> => {
    switch (action.type) {
      case MARK_FIRST_RUN:
        return { ...state, isInitialRun: false }
      case FETCH_INIT:
        return {
          ...state,
          isLoading: true,
          isError: false,
          isInitialRun: false,
        }
      case FETCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          payload: action.payload,
        }
      case FETCH_FAILURE:
        return { ...state, isLoading: false, isError: true }
      default:
        throw new Error()
    }
  }

const createInitialState = (hookOptions: IFetchHook<any, any>) => {
  const isLoading = !hookOptions.disable

  return {
    isLoading,
    isError: false,
    isInitialRun: true,
  }
}

const useGenericFetchFromApi = <Q, P>(
  hookOptions: IFetchHook<Q, P>
): [IStateType<P>, (queryParams: Q) => void, () => void] => {
  const { initialQueryParams, fetchFunction, disable } = hookOptions
  const [queryParams, setQueryParams] = useState(initialQueryParams)
  const [manualFetchCounter, setManualFetchCounter] = useState<number>(0)
  const [state, dispatch] = useReducer(
    createFetchDataReducer<P>(),
    createInitialState(hookOptions)
  )

  const doManualFetch = useCallback(() => {
    setManualFetchCounter(manualFetchCounter + 1)
  }, [manualFetchCounter])

  useEffect(() => {
    let didCancel = false

    // we need the following function to get around await returning implicit promise
    // https://www.robinwieruch.de/react-hooks-fetch-data/
    const fetchData = async () => {
      dispatch({ type: FETCH_INIT })

      try {
        const result = await fetchFunction(queryParams)

        if (!didCancel) {
          dispatch({ type: FETCH_SUCCESS, payload: result })
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: FETCH_FAILURE })
        }
      }
    }

    // do not run the initial fetch if delayFetch parameter is provided
    if (!disable) {
      fetchData()
    }

    // cleanup function to prevent updating state of unmounted component
    // also fires whenever the effect is re-run
    return () => {
      didCancel = true
    }
  }, [queryParams, fetchFunction, disable, manualFetchCounter])

  return [state, setQueryParams, doManualFetch]
}

export default useGenericFetchFromApi
