/**
 * Parses and sanitizes a text as number
 * - Handles `.` and `,` as either decimal or thousand separator
 * - Ignores all non-numeric chars
 * - Returns 0 as fallback if value can not be interpreted as number
 * @param text
 * @param maxFractionDigits
 */
export const safeParseNumber = (text: string, maxFractionDigits?: number) => {
  let temp = text

  // Handle `.` and `,` as decimal separator
  temp = temp.replace(/,/g, '.')

  // Remove all separators except decimal separator
  const parts = temp.split('.')
  temp =
    parts.length > 1
      ? `${parts.slice(0, -1).join('')}.${parts.slice(-1)}`
      : temp

  // Remove all non-numeric characters
  temp = temp.replace(/[^\d.]/g, '')

  if (maxFractionDigits !== undefined) {
    // Ensure max. fraction digits
    temp = parseFloat(temp).toFixed(Math.max(0, maxFractionDigits))
  }

  // Convert to number, use zero as fallback
  return parseFloat(temp) || 0
}
