import React, { useState } from 'react'
import cn from 'classnames'
import last from 'lodash/last'
import Link from 'next/link'
import {
  LeftOutlined,
  CloseOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import { ITaxonomy } from '@core/api/Categories/types'
import { ITaxonomyAggregationLeaf } from '@core/api/Products/types'
import {
  LYSButton,
  LYSCard,
  LYSIcon,
  LYSLoading,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import { usePageContext } from '@core/utils/pages/PageContext'
import style from './index.module.less'

export interface CategoryFilterProps {
  className?: string
  showRootNavigation?: boolean
  containerClassName?: string
}

const getSelectableTaxonomies = (
  root: IPossibleTaxonomyTypes,
  isSearchResultPage?: boolean
) => {
  if (!isSearchResultPage) {
    return root.children
  }

  // only on the search result page
  // get flat list of all taxonomies below currently filtered taxonomy (or root)
  const descendants = getDescendantTaxonomies(root)

  // Order by product count, desc.
  descendants.sort((left, right) => {
    if ('productCount' in left && 'productCount' in right) {
      return right.productCount - left.productCount
    } else {
      return 0
    }
  })

  // Pick top 5
  return descendants.slice(0, 10)
}

type IPossibleTaxonomyTypes = ITaxonomy | ITaxonomyAggregationLeaf

function getTaxonomyPath<T extends IPossibleTaxonomyTypes>(
  taxonomyId: string,
  root: T
): T[] | undefined {
  if (taxonomyId === root.id) {
    return [root]
  }

  for (const child of root.children) {
    const result = getTaxonomyPath(taxonomyId, child)
    if (result) {
      return [root, ...result] as T[]
    }
  }

  return undefined
}

function getDescendantTaxonomies<T extends IPossibleTaxonomyTypes>(
  node: IPossibleTaxonomyTypes
): T[] {
  return (node.children as any).reduce(
    (acc: T[] | [], child: T) => [
      ...acc,
      child,
      ...getDescendantTaxonomies(child),
    ],
    [] as Array<ITaxonomyAggregationLeaf>
  )
}

export const CategoryFilter: React.FC<CategoryFilterProps> = ({
  className,
  showRootNavigation,
  containerClassName,
}) => {
  const {
    queryToFetch,
    taxonomyAggregationRoot,
    setTaxonomyFilter,
    showCategoryFilter,
    isSearchResultPage,
    isLoading,
  } = useProductCollectionContext() || {}

  const {
    props: { isMobile },
  } = usePageContext()

  const { root } = useCategoryNavigation()
  const { t } = useTranslation()

  const [showFilter, setShowFilter] = useState(!isMobile)
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true)

  if (isLoading) {
    return (
      <LYSCard bordered={false} className={style.box} size="small">
        <LYSRow align="middle" gutter="md">
          <div className={style.loadingSpinnerHolder}>
            <LYSLoading />
          </div>
        </LYSRow>
      </LYSCard>
    )
  }
  // in the search page we use the category tree returned by the GET /products endpoint
  // in the category page we use the categroy tree returned by the GET taxonomies/tree endpoint
  // this is because the endpoints use different category.id formats
  const categoryTreeRoot = isSearchResultPage
    ? taxonomyAggregationRoot
      ? taxonomyAggregationRoot
      : root
    : root

  if (!showCategoryFilter || !categoryTreeRoot) return null

  const handleShowFilter = () => setShowFilter(true)
  const handleHideFilter = () => setShowFilter(false)

  const handleTaxonomyClick = (id: string) => {
    if (isMobile && showFilter === true) {
      setShowFilter(false)
    }
    if (isSearchResultPage) {
      setTaxonomyFilter!(id)
    }
  }

  const renderTaxonomyLink = (taxonomy: IPossibleTaxonomyTypes) => {
    return (
      <div
        className={cn(
          className,
          style.childCategory,
          currentlyFilteredTaxonomyPath && style.indent
        )}
        key={taxonomy.id}
      >
        <Link href={`/c/${taxonomy.slug}/${taxonomy.id}`}>{taxonomy.name}</Link>
      </div>
    )
  }

  const renderTaxonomyPath = (path: IPossibleTaxonomyTypes[]) => {
    if (!path) return null
    const parentCategory = path[path.length - 2]
    const currentCategory = path[path.length - 1]

    return (
      <>
        {parentCategory && (
          <div
            className={cn(className, style.parentCategory)}
            key={parentCategory.id}
          >
            {isSearchResultPage ? (
              <div
                key={parentCategory.id}
                onClick={() => handleTaxonomyClick(parentCategory.id)}
                className={style.parentCategoryWrap}
              >
                <LeftOutlined rev={undefined} />
                <div>
                  {parentCategory.name}{' '}
                  {'productCount' in parentCategory && (
                    <span
                      className={style.categoryCount}
                    >{`(${parentCategory.productCount})`}</span>
                  )}
                </div>
              </div>
            ) : (
              <div className={style.parentCategoryWrap}>
                <Link href={`/c/${parentCategory.slug}/${parentCategory.id}`}>
                  <a onClick={() => handleTaxonomyClick(parentCategory.id)}>
                    <LeftOutlined rev={undefined} />
                    <span className={style.parentCategoryName}>
                      {parentCategory.name}
                    </span>
                  </a>
                </Link>
              </div>
            )}
          </div>
        )}
        {currentCategory && (
          <div
            className={cn(style.currentCategory, className)}
            key={currentCategory.id}
          >
            {isSearchResultPage ? (
              <div
                key={currentCategory.id}
                onClick={() => handleTaxonomyClick!(currentCategory.id)}
              >
                {currentCategory.name}{' '}
                {'productCount' in currentCategory && (
                  <span
                    className={style.categoryCount}
                  >{`(${currentCategory.productCount})`}</span>
                )}
              </div>
            ) : (
              <Link href={`/c/${currentCategory.slug}/${currentCategory.id}`}>
                <a onClick={() => handleTaxonomyClick(currentCategory.id)}>
                  {currentCategory.name}{' '}
                  {taxonomyAggregationRoot?.productCount && (
                    <span
                      className={style.categoryCount}
                    >{`(${taxonomyAggregationRoot?.productCount})`}</span>
                  )}
                </a>
              </Link>
            )}
          </div>
        )}
      </>
    )
  }

  const renderSelectableTaxonomy = (taxonomy: IPossibleTaxonomyTypes) => (
    <div
      className={cn(
        className,
        style.childCategory,
        currentlyFilteredTaxonomyPath && style.indent
      )}
      key={taxonomy.id}
    >
      {isSearchResultPage ? (
        <div
          key={taxonomy.id}
          onClick={() => handleTaxonomyClick!(taxonomy.id)}
        >
          {taxonomy.name}{' '}
          {'productCount' in taxonomy && (
            <span
              className={style.categoryCount}
            >{`(${taxonomy.productCount})`}</span>
          )}
        </div>
      ) : (
        <Link href={`/c/${taxonomy.slug}/${taxonomy.id}`}>
          <a onClick={() => handleTaxonomyClick(taxonomy.id)}>
            {taxonomy.name}
          </a>
        </Link>
      )}
    </div>
  )

  const currentlyFilteredTaxonomyId = showRootNavigation
    ? categoryTreeRoot.id
    : (queryToFetch?.filter?.taxonomy as string)
  const currentlyFilteredTaxonomyPath = currentlyFilteredTaxonomyId
    ? getTaxonomyPath(currentlyFilteredTaxonomyId, categoryTreeRoot)
    : undefined

  const selectableTaxonomies = getSelectableTaxonomies(
    last(currentlyFilteredTaxonomyPath) || categoryTreeRoot
  )
  const shouldShowMoreButton: boolean = selectableTaxonomies.length > 5

  if (!currentlyFilteredTaxonomyPath && selectableTaxonomies.length === 0)
    return null

  const clickHandler = () => setShowMoreButton(!showMoreButton)

  return (
    <>
      {isMobile ? (
        <>
          <LYSButton
            icon={<UnorderedListOutlined rev={undefined} />}
            onClick={handleShowFilter}
            className={style.mobileButton}
          >
            {t('flyOut.productCategoriesText')}
          </LYSButton>

          {showFilter && (
            <div className={style.overlay}>
              <div className={style.mobileOverlayHeader}>
                <LYSTypography.Title level={3}>
                  {t('flyOut.productCategoriesText')}
                </LYSTypography.Title>
                <LYSButton
                  className={style.closeButton}
                  type={'text'}
                  onClick={handleHideFilter}
                >
                  <LYSIcon
                    component={CloseOutlined}
                    size={'sm'}
                    title={'Close'}
                  />
                </LYSButton>
              </div>
              <div className={cn(style.menu, className)}>
                {currentlyFilteredTaxonomyPath &&
                  renderTaxonomyPath(currentlyFilteredTaxonomyPath)}
                {selectableTaxonomies &&
                  selectableTaxonomies.map((taxonomy) =>
                    renderSelectableTaxonomy(taxonomy)
                  )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {showFilter &&
            (showRootNavigation ? (
              <div
                className={cn(style.menu, className, containerClassName)}
                data-testid="category-navigation"
              >
                {currentlyFilteredTaxonomyPath &&
                  renderTaxonomyPath(currentlyFilteredTaxonomyPath)}
                {selectableTaxonomies &&
                  selectableTaxonomies.map((taxonomy) =>
                    renderTaxonomyLink(taxonomy)
                  )}
              </div>
            ) : (
              <div
                className={cn(style.menu, className)}
                data-testid="category-filter"
              >
                {currentlyFilteredTaxonomyPath &&
                  renderTaxonomyPath(currentlyFilteredTaxonomyPath)}
                {selectableTaxonomies &&
                  selectableTaxonomies.map((taxonomy, index) =>
                    showMoreButton
                      ? index < 5 && renderSelectableTaxonomy(taxonomy)
                      : renderSelectableTaxonomy(taxonomy)
                  )}
                {shouldShowMoreButton && (
                  <LYSButton
                    className={style.moreButton}
                    icon={
                      showMoreButton ? (
                        <PlusOutlined rev={undefined} />
                      ) : (
                        <MinusOutlined rev={undefined} />
                      )
                    }
                    type="link"
                    onClick={() => clickHandler()}
                  >
                    {showMoreButton
                      ? t('button.showMore')
                      : t('button.showLess')}
                  </LYSButton>
                )}
              </div>
            ))}
        </>
      )}
    </>
  )
}
