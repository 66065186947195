import React, { useState, useEffect, useCallback } from 'react'
import { DownOutlined } from '@ant-design/icons'
import {
  IProductAggregationRange,
  PRICE_KEY,
  IProductsQuery,
  IProductsQueryFilterRange,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCol,
  LYSDropdown,
  LYSIcon,
  LYSMenu,
  LYSRow,
  LYSInputNumber,
} from '@core/components/Primitives'
import i18n from '@core/config/i18n'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../useProductCollectionQuery'
import style from './index.module.less'

export const removeDecimalsFromPrice = (
  priceWithDecimals: number,
  roundingFunction: (x: number) => number
) => roundingFunction(priceWithDecimals / 100)

export const addDecimalsToPrice = (price: number) => price * 100

export const formatInputValues = (
  min: number,
  max: number
): [number, number] => [
  removeDecimalsFromPrice(min, Math.floor),
  removeDecimalsFromPrice(max, Math.ceil),
]

export interface PriceFilterProps {
  aggregation: IProductAggregationRange
  query: IProductsQuery
}

const PriceFilter: React.FC<PriceFilterProps> = ({ aggregation, query }) => {
  const { setPriceRangeFilter } = useProductCollectionQuery()
  const urlValues = query.filter?.[PRICE_KEY] as
    | IProductsQueryFilterRange
    | undefined

  const urlOrAggregationValues = formatInputValues(
    urlValues?.gte || aggregation.min,
    urlValues?.lte || aggregation.max
  )

  const [value, setValue] = useState<[number, number]>(urlOrAggregationValues)
  const [isOpen, setOpen] = useState(false)

  const { t } = useTranslation()

  const isActive = !!urlValues

  // we need the filter to refresh it's state if the filter was removed outside of component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => setValue(urlOrAggregationValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(urlOrAggregationValues)]
  )
  const handleSetFilter = useCallback(() => {
    setOpen(false)
    setPriceRangeFilter(PRICE_KEY, [
      addDecimalsToPrice(value[0]),
      addDecimalsToPrice(value[1]),
    ])
  }, [setPriceRangeFilter, value])

  const renderNumberInput = (position: 0 | 1) => (
    <LYSInputNumber
      value={value[position]}
      formatter={(unformattedValue = 0) =>
        unformattedValue + ' ' + i18n.currencySymbol
      }
      parser={(price) => (price ? price.replace(/\D/g, '') : '0')}
      onChange={(newValue = 0) => {
        const fullValue = [...value]
        fullValue[position] =
          (newValue as number) > 0 ? (newValue as number) : 0
        setValue([fullValue[0], fullValue[1]])
      }}
    />
  )

  const filterDetail = () => (
    <LYSMenu>
      <LYSMenu.Item className={style.menuItem}>
        <LYSRow gutter="sm">
          <LYSCol>{renderNumberInput(0)}</LYSCol>
          <LYSCol>{renderNumberInput(1)}</LYSCol>
        </LYSRow>
      </LYSMenu.Item>
      <LYSMenu.Divider />

      <LYSMenu.Item>
        <LYSButton
          type="primary"
          block={true}
          onClick={handleSetFilter}
          size={'small'}
        >
          {t('filter.save')}
        </LYSButton>
      </LYSMenu.Item>
    </LYSMenu>
  )

  return (
    <LYSDropdown
      onVisibleChange={(flag) => setOpen(flag)}
      visible={isOpen}
      overlay={filterDetail}
      trigger={['click']}
    >
      <LYSButton
        type={isActive ? 'primary' : undefined}
        ghost={isActive ? true : undefined}
        className={style.button}
        size={'small'}
        data-testid={'price-filter-open-btn'}
      >
        {t('filter.labels.price', PRICE_KEY)}{' '}
        <LYSIcon component={DownOutlined} />
      </LYSButton>
    </LYSDropdown>
  )
}

export default PriceFilter
