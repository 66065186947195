import { LYSCheckbox } from '@core/components/Primitives'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'
import { useTranslation } from '@core/i18n/i18n'
import config from '@core/config/config'
import style from '../index.module.less'
import { useCallback } from 'react'

export const IsAwardsFilter: React.FC = () => {
  const { t } = useTranslation()
  const { onlyAwardsFilterActive, setAwardsFilter } =
    useProductCollectionQuery()

  const toggleAwardSwitch = useCallback(() => {
    setAwardsFilter(!onlyAwardsFilterActive, 'awards', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyAwardsFilterActive])

  const awardIcon =
    config.features.filters.awardsFilter.active &&
    config.features.filters.awardsFilter.icon ? (
      <img
        className={style.awardsLogo}
        src={config.features.filters.awardsFilter.icon}
        alt={config.features.filters.awardsFilter.filterKey}
      />
    ) : null

  return config.features.filters.awardsFilter.active ? (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleAwardSwitch}
        checked={onlyAwardsFilterActive}
      >
        {awardIcon}{' '}
        {t(`filter.${config.features.filters.awardsFilter.filterKey}`)}
      </LYSCheckbox>
    </span>
  ) : null
}

export default IsAwardsFilter
