import React from 'react'
import { LYSCol, LYSRow } from '@core/components/Primitives'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import ProductSkeleton from '../ProductCollectionItemSkeleton'

export interface LoadingStateProps {
  className?: string
}

const LoadingState: React.FC<LoadingStateProps> = ({ className }) => {
  const { query, isLoading } = useProductCollectionContext() || {}

  return isLoading ? (
    <LYSRow className={className} gutter="md">
      {[...Array(query?.limit)].map((_, index) => (
        <LYSCol xs={24} sm={12} lg={8} xl={6} key={index}>
          <ProductSkeleton />
        </LYSCol>
      ))}
    </LYSRow>
  ) : null
}

export default LoadingState
