import { useMemo } from 'react'
import { IOrderCollection, IOrdersQuery } from '@core/api/Orders/types'
import { useServices } from '@core/utils/ioc'
import useGenericFetchFromApi, { IStateType } from './useGenericFetchFromApi'

const useFetchOrders = (
  initialFilter?: IOrdersQuery
): [
  IStateType<IOrderCollection>,
  (filter: IOrdersQuery) => void,
  () => void
] => {
  const { api } = useServices()
  const fetchFunction = useMemo(() => api.orders.fetchOrders.bind(api, initialFilter), [api, initialFilter])

  return useGenericFetchFromApi<IOrdersQuery | undefined, IOrderCollection>({
    initialQueryParams: initialFilter,
    fetchFunction,
  })
}

export default useFetchOrders
