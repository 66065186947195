import React from 'react'
import { LYSCheckbox } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from '../index.module.less'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'

const OnProductListFilter: React.FC = () => {
  const { t } = useTranslation()
  const { onlyOnProductListActive, toggleOnProductListProducts } =
    useProductCollectionQuery()

  return (
    <span className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleOnProductListProducts}
        checked={onlyOnProductListActive}
      >
        <label data-testid={'only-on-product-list-products-toggle'}>
          {t('filter.onProductList')}
        </label>
      </LYSCheckbox>
    </span>
  )
}

export default OnProductListFilter
