import * as React from 'react'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useDispatch, useSelector } from 'react-redux'
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { ProductViewType } from '@core/api/Products/types'
import { LYSIcon } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import { setProductView } from '@core/store/product-detail/actions'
import { getProductView } from '@core/store/product-detail/selectors'
import style from './index.module.less'

const ProductViewSwitch: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const productView = useSelector(getProductView)

  const onViewChange = React.useCallback(
    (e: RadioChangeEvent) => {
      dispatch(setProductView(e.target.value as ProductViewType))
    },
    [dispatch]
  )

  return (
    <div className={style.viewSwitchCol}>
      <Radio.Group
        defaultValue={productView}
        buttonStyle="outline"
        size={'small'}
        onChange={onViewChange}
      >
        <Radio.Button value={ProductViewType.GRID}>
          <LYSIcon component={AppstoreOutlined} /> {t('category.gridViewText')}
        </Radio.Button>
        <Radio.Button value={ProductViewType.LIST}>
          <LYSIcon component={UnorderedListOutlined} />{' '}
          {t('category.listViewText')}
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default ProductViewSwitch
