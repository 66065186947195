import { useEffect, useMemo } from 'react'
import {
  IProductAggregation,
  IProductCollection,
  IProductsQuery,
  ITaxonomyAggregationLeaf,
} from '@core/api/Products/types'
import { useServices } from '@core/utils/ioc'
import useGenericFetchFromApi from './useGenericFetchFromApi'

interface FetchedProductAggregations {
  isLoading: boolean
  isError: boolean
  aggregations?: IProductAggregation[]
  taxonomyAggregationRoot?: ITaxonomyAggregationLeaf
}

const useFetchAggregations = (
  query: IProductsQuery
): FetchedProductAggregations => {
  const { productQueryService } = useServices()

  const fetchFunction = useMemo(
    () => productQueryService.runAggregationsQuery.bind(productQueryService),
    [productQueryService]
  )

  const stringifiedQuery = JSON.stringify(query)

  const memoizedQuery = useMemo(
    () => query,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedQuery]
  )

  const [{ payload, ...fetchStatus }, setFetchQuery] = useGenericFetchFromApi<
    IProductsQuery | undefined,
    IProductCollection
  >({
    initialQueryParams: memoizedQuery,
    fetchFunction,
  })

  useEffect(() => {
    setFetchQuery(memoizedQuery)
  }, [memoizedQuery, setFetchQuery])

  return {
    ...fetchStatus,
    aggregations: payload?.aggregations,
    taxonomyAggregationRoot: payload?.taxonomyAggregationRoot,
  }
}

export default useFetchAggregations
