import React from 'react'
import { LYSCheckbox } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import useProductCollectionQuery from '../../../useProductCollectionQuery'
import style from '../index.module.less'

const InStockFilter: React.FC = () => {
  const { t } = useTranslation()

  const { onlyInStockFilterActive, toggleOnlyInStock } =
    useProductCollectionQuery()

  return (
    <span key={'inStock'} className={style.toggleItem}>
      <LYSCheckbox
        onChange={toggleOnlyInStock}
        checked={onlyInStockFilterActive}
        className={style.inStockSwitch}
      >
        {t`filter.onlyInStock`}
      </LYSCheckbox>
    </span>
  )
}

export default InStockFilter
