import React from 'react'
import ProductViewSwitch from '@core/components/Category/ProductViewSwitch'
import { LYSCol, LYSRow } from '@core/components/Primitives'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import SortingControl from '@core/components/ProductCollection/SortingControl'
import ProductCollectionActiveTags from '@core/components/ProductCollection//Filters/ActiveTags'
import ProductCollectionFilters from '@core/components/ProductCollection/Filters'
export interface ProductCollectionControlsProps {
  className?: string
  isEmptySearchResult?: boolean
}

export const ProductCollectionControls: React.FC<ProductCollectionControlsProps> =
  (props) => {
    const { hideControls, queryToFetch } = useProductCollectionContext() || {}
    const { className, isEmptySearchResult } = props

    return (
      <>
        {isEmptySearchResult && <ProductCollectionFilters />}
        {hideControls ? null : (
          <LYSRow
            justify={'space-between'}
            align={'middle'}
            gutter={'md'}
            className={className}
          >
            <LYSCol>
              <SortingControl />
            </LYSCol>
            <LYSCol>
              <ProductViewSwitch />
            </LYSCol>
          </LYSRow>
        )}
        {queryToFetch && queryToFetch.filter && (
          <LYSRow
            justify={'space-between'}
            align={'middle'}
            gutter={'md'}
            className={className}
          >
            <ProductCollectionActiveTags />
          </LYSRow>
        )}
      </>
    )
  }
