import React, { useContext } from 'react'
import { ProductCollectionContext } from '@core/utils/models/product/types'

const ReactProductCollectionContext = React.createContext<
  ProductCollectionContext | undefined
>(undefined)

export const ProductCollectionContextProvider: React.FC<{
  productCollectionContext: ProductCollectionContext
}> = ({ children, productCollectionContext }) => {
  return (
    <ReactProductCollectionContext.Provider value={productCollectionContext}>
      {children}
    </ReactProductCollectionContext.Provider>
  )
}

export const useProductCollectionContext = () => {
  return useContext(ReactProductCollectionContext)
}
