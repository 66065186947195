import React, { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { StockInformationTypes } from '@core/api/Channel/types'
import {
  AggregationsTypes,
  IProductAggregationRange,
  IProductAggregationTerm,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCard,
  LYSCol,
  LYSLoading,
  LYSRow,
} from '@core/components/Primitives'
import { CategoryFilter } from '@core/components/ProductCollection/Filters/CategoryFilter'
import useProductCollectionQuery from '@core/components/ProductCollection/useProductCollectionQuery'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { useProductCollectionContext } from '@core/utils/models/product/ProductCollectionContext'
import { RenderProp, Slot, template } from '@core/utils/templates'
import useFetchAggregations from '@core/utils/useFetchAggregations'
import ProductCollectionActiveTags from './ActiveTags'
import InStockFilter from './InStockFilter'
import PriceFilter from './PriceFilter'
import SimpleFilter from './SimpleFilter'
import OnProductListFilter from './OnProductListFilter'
import { getValidAggregations } from './helper'
import style from './index.module.less'
import config from '@core/config/config'
import {
  OnlyPurchasedProducts,
  OnlyRecentlyPurchasedProducts,
} from './OnlyPurchasedFilters'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import useFetchOrders from '@core/utils/useFetchOrders'
import { IOrder } from '@core/api/Orders/types'
import useProductLists from '@core/hooks/productList/useProductLists'
import { OnlyWithPriceFilter } from '@core/components/ProductCollection/Filters/OnlyWithPriceFilter'

const COLLAPSED_FILTER_COUNT = 6

export interface ProductCollectionFiltersProps {
  showCategoryFilter?: boolean
}

export interface ProductCollectionFilersSlots {
  loading?: RenderProp<void>
  expandButton?: RenderProp<{ filtersExpanded: boolean }>
  filterContainer?: RenderProp<void>
  onlyWithPriceFilter?: RenderProp<void>
  newProduct?: RenderProp<void>
  activeFilters?: RenderProp<void>
  showOnlyPurchasedProducts?: RenderProp<void>
  showOnlyRecentlyPurchasedProducts?: RenderProp<void>
  showOnProductListFilter?: RenderProp<void>
}

export interface ProductCollectionFiltersComponents {
  PriceFilter: typeof PriceFilter
  SimpleFilter: typeof SimpleFilter
  CategoryFilter: typeof CategoryFilter
  InStockFilter: typeof InStockFilter
}

const ProductCollectionFilters = template<
  ProductCollectionFiltersProps,
  ProductCollectionFilersSlots,
  ProductCollectionFiltersComponents
>(({ slots, getComponents }) => {
  const { t } = useTranslation()
  const { userChannel } = useChannel()
  const { isAuthenticated } = useCurrentUser()
  const [ordersRequest] = useFetchOrders()

  const orders: IOrder[] =
    (ordersRequest.payload && ordersRequest.payload.orders) || []

  const { productLists } = useProductLists()

  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

  const stockInformationType = userChannel?.displayStockMode

  const { queryToFetch, brandShortId } = useProductCollectionContext() || {}

  const filterKeysToHide = brandShortId ? ['brand'] : []

  if (!queryToFetch) return null

  const { aggregations, isLoading } = useFetchAggregations(queryToFetch)

  const filters = getValidAggregations(aggregations || [], filterKeysToHide)
  const { setTermFilter } = useProductCollectionQuery()

  const [priceFilter] = useMemo(
    () => filters.filter((filter) => filter.filterKey === 'price'),
    [filters]
  )
  const [brandsFilter] = useMemo(
    () => filters.filter((filter) => filter.filterKey === 'brand'),
    [filters]
  )

  const [awardsFilter] = useMemo(
    () =>
      filters.filter(
        (filter) =>
          filter.filterKey === config.features.filters.awardsFilter.filterKey
      ),
    [filters]
  )

  const filtersWithoutPriceAndBrandAndAwards = useMemo(
    () =>
      filters.filter(
        (filter) =>
          filter.filterKey !== 'brand' &&
          filter.filterKey !== 'price' &&
          filter.filterKey !== config.features.filters.awardsFilter.filterKey
      ),
    [filters]
  )

  const visibleFilters = useMemo(
    () =>
      filtersWithoutPriceAndBrandAndAwards.slice(
        0,
        filtersExpanded
          ? filtersWithoutPriceAndBrandAndAwards.length
          : COLLAPSED_FILTER_COUNT
      ),
    [filtersWithoutPriceAndBrandAndAwards, filtersExpanded]
  )

  const showExpandButton = filters.length > COLLAPSED_FILTER_COUNT
  const handleToggleExpanded = () => setFiltersExpanded(!filtersExpanded)

  const components = getComponents({
    PriceFilter,
    SimpleFilter,
    CategoryFilter,
    InStockFilter,
  })

  if (isLoading)
    return (
      <Slot render={slots?.loading}>
        <LYSCard bordered={false} className={style.box} size="small">
          <LYSRow align="middle" gutter="md">
            <div className={style.loadingSpinnerHolder}>
              <LYSLoading />
            </div>
          </LYSRow>
        </LYSCard>
      </Slot>
    )

  return (
    <>
      <LYSCard
        bordered={false}
        className={style.box}
        size="small"
        data-testid="product-collection-filters-card"
      >
        <LYSRow align="middle" gutter={['md', 'xs']}>
          <Slot render={slots?.filterContainer}>
            {!isEmpty(priceFilter) && (
              <LYSCol key={priceFilter.filterKey}>
                <components.PriceFilter
                  query={queryToFetch}
                  aggregation={priceFilter as IProductAggregationRange}
                />
              </LYSCol>
            )}
            {!isEmpty(brandsFilter) && (
              <LYSCol key={brandsFilter.filterKey}>
                <components.SimpleFilter
                  query={queryToFetch}
                  label={t(
                    `filter.labels.${brandsFilter.filterLabel}`,
                    brandsFilter.filterLabel
                  )}
                  onSetFilter={setTermFilter}
                  filterKey={brandsFilter.filterKey}
                  aggregation={brandsFilter as IProductAggregationTerm}
                  isBrandFilter
                />
              </LYSCol>
            )}
            {!isEmpty(awardsFilter) && (
              <LYSCol key={awardsFilter.filterKey}>
                <components.SimpleFilter
                  query={queryToFetch}
                  label={t(
                    `filter.labels.${awardsFilter.filterLabel}`,
                    awardsFilter.filterLabel
                  )}
                  onSetFilter={setTermFilter}
                  filterKey={awardsFilter.filterKey}
                  aggregation={awardsFilter as IProductAggregationTerm}
                  isAwardFilter
                />
              </LYSCol>
            )}
            {stockInformationType !== StockInformationTypes.disabled && (
              <LYSCol key={'inStock'}>
                <components.InStockFilter
                  query={queryToFetch}
                  onSetFilter={setTermFilter}
                  filterKey={'onlyInStock'}
                />
              </LYSCol>
            )}
            {visibleFilters.map((filter) => {
              if (filter.filterKey === 'attributes.114') return null
              return (
                <LYSCol key={filter.filterKey}>
                  {filter.type === AggregationsTypes.RANGE ? (
                    <components.PriceFilter
                      query={queryToFetch}
                      aggregation={filter}
                    />
                  ) : (
                    <components.SimpleFilter
                      query={queryToFetch}
                      label={t(
                        `filter.labels.${filter.filterLabel}`,
                        filter.filterLabel
                      )}
                      onSetFilter={setTermFilter}
                      filterKey={filter.filterKey}
                      aggregation={filter}
                    />
                  )}
                </LYSCol>
              )
            })}
          </Slot>
          {showExpandButton && (
            <Slot render={slots?.expandButton} props={{ filtersExpanded }}>
              <LYSButton
                onClick={handleToggleExpanded}
                type="link"
                icon={
                  filtersExpanded ? (
                    <MinusOutlined rev={undefined} />
                  ) : (
                    <PlusOutlined rev={undefined} />
                  )
                }
              >
                {t(
                  filtersExpanded ? 'filter.lessFilters' : 'filter.moreFilters'
                )}
              </LYSButton>
            </Slot>
          )}
          {queryToFetch.filter && (
            <LYSCol xs={24}>
              <Slot render={slots?.activeFilters}>
                <ProductCollectionActiveTags />
              </Slot>
            </LYSCol>
          )}
          <LYSCol xs={24} className={style.onlyPurchasedFilterContainer}>
            {config.features.filters.showOnlyPurchasedProducts &&
              isAuthenticated &&
              orders.length > 0 && (
                <Slot render={slots?.showOnlyPurchasedProducts}>
                  <OnlyPurchasedProducts />
                </Slot>
              )}
            {config.features.filters.showOnlyRecentlyPurchasedProducts &&
              isAuthenticated &&
              orders.length > 0 && (
                <Slot render={slots?.showOnlyRecentlyPurchasedProducts}>
                  <OnlyRecentlyPurchasedProducts />
                </Slot>
              )}
            {config.features.filters.showOnProductListFilter &&
              isAuthenticated &&
              productLists.length > 0 && (
                <Slot render={slots?.showOnProductListFilter}>
                  <OnProductListFilter />
                </Slot>
              )}
            {config.features.filters.showOnlyWithPriceFilter &&
              !isEmpty(priceFilter) && (
                <Slot render={slots?.onlyWithPriceFilter}>
                  <OnlyWithPriceFilter />
                </Slot>
              )}
          </LYSCol>
        </LYSRow>
      </LYSCard>
    </>
  )
})

export default ProductCollectionFilters
