import { LYSSelect, LYSSelectOption } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import config from '@core/config/config'
import { DEFAULT_VALUE, decodeSortingValue, encodeSortingValue } from '.'
import useProductCollectionQuery from '../useProductCollectionQuery'
import style from './index.module.less'

const MobileSortingControl: React.FC = () => {
  const { t } = useTranslation()
  const { query, setOrder, removeKey } = useProductCollectionQuery()

  return (
    <LYSSelect
      size="large"
      className={style.select}
      onSelect={(value: string) => {
        if (typeof value !== 'string') return

        if (value === DEFAULT_VALUE) {
          removeKey('order')
        } else {
          setOrder(decodeSortingValue(value))
        }
      }}
      value={query.order ? encodeSortingValue(query.order) : DEFAULT_VALUE}
    >
      <LYSSelectOption value={DEFAULT_VALUE}>
        {t('product.productCollection.sorting')}
      </LYSSelectOption>
      {config.features.newProductsFeature && (
        <LYSSelectOption value={encodeSortingValue({ created: 'desc' })}>
          {t('product.productCollection.newestProductsText')}
        </LYSSelectOption>
      )}
      <LYSSelectOption value={encodeSortingValue({ price: 'asc' })}>
        {t('product.productCollection.priceAscText')}
      </LYSSelectOption>
      <LYSSelectOption value={encodeSortingValue({ price: 'desc' })}>
        {t('product.productCollection.priceDescText')}
      </LYSSelectOption>
      <LYSSelectOption value={encodeSortingValue({ sku: 'asc' })}>
        {t('product.productCollection.itemNumberText')}
      </LYSSelectOption>
      <LYSSelectOption value={encodeSortingValue({ name: 'asc' })}>
        {t('product.productCollection.nameText')}
      </LYSSelectOption>
    </LYSSelect>
  )
}

export default MobileSortingControl
