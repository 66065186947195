import React from 'react'

import MobileAddToCart from '@core/components/ProductCollection/MobileAddToCart'

import useCart from '@core/hooks/cart/useCart'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import {
  getPackagingUnitsFromContext,
  useProductPriceRequestContext,
  useProductSearchContext,
} from '@core/utils/models/product/ProductContext'
import style from './index.module.less'
import PriceRequestPendingInfo from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestPendingInfo'
import PriceRequestCta from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestCta'
import PriceRequestStatusInfo from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestStatusInfo'
import { LYSCol, LYSRow } from '@core/components/Primitives'
import AddToProductListSelect from '@core/components/Me/ProductList/AddToProductListSelect'
import useProductLists from '@core/hooks/productList/useProductLists'
import { ICreateProductList } from '@core/api/ProductLists/types'
import { usePageContext } from '@core/utils/pages/PageContext'

interface OwnProps {
  hidePriceRequestPendingInfo?: boolean
  hideAddToCartButtonLabel?: boolean
}

const MobileProductActionElements: React.FC<OwnProps> = ({
  hidePriceRequestPendingInfo,
}) => {
  const { addToCart } = useCart()

  const productSearchContext = useProductSearchContext()
  const productPriceRequestContext = useProductPriceRequestContext()
  const { addToProductList, createProductList } = useProductLists()
  const productContext = productSearchContext ?? productPriceRequestContext

  const { product } = productContext ?? {}

  const { isPendingPriceRequest, isRequestable } = usePriceRequests(product)

  const {
    props: { isMobile },
  } = usePageContext()

  if (!productContext || !product) return null

  const { packagingUnit } = getPackagingUnitsFromContext(productContext)
  if (!packagingUnit) return null

  const { multiplier } = packagingUnit

  const {
    rules: { isPurchasable },
  } = productContext

  const hasPrice = !!packagingUnit.price

  const { isDiscontinued } = product

  return (
    <LYSRow justify="space-around" align="middle">
      <LYSCol sm={20}>
        {!isDiscontinued && (
          <div className={style.buttons}>
            {!hasPrice &&
              !hidePriceRequestPendingInfo &&
              isPendingPriceRequest && <PriceRequestStatusInfo />}

            {!isRequestable && (
              <MobileAddToCart
                onAddToCart={() =>
                  addToCart(product.mainVariant, product.name, multiplier * 1)
                }
                isAllowedToAddToCart={productContext.rules.isPurchasable}
                purchasable={isPurchasable}
              />
            )}
            {!hasPrice && isRequestable && (
              <>
                {isPendingPriceRequest ? (
                  !isMobile ? (
                    <PriceRequestPendingInfo paddingBottom={false} />
                  ) : (
                    <PriceRequestCta
                      variantId={product.mainVariant.id}
                      disabled={true}
                    />
                  )
                ) : null}
                {!isPendingPriceRequest && (
                  <PriceRequestCta variantId={product.mainVariant.id} />
                )}
              </>
            )}
          </div>
        )}
        <AddToProductListSelect
          onSelectProductList={(productListId: string) =>
            addToProductList(productListId, product.mainVariant.id, 1)
          }
          onCreateProductList={(productList: ICreateProductList) =>
            createProductList(productList, [
              { variantId: product.mainVariant.id, quantity: 1 },
            ])
          }
          notAllowedToAddToProductList={!productContext.rules.isPurchasable}
          product={product}
        />
      </LYSCol>
    </LYSRow>
  )
}

export default MobileProductActionElements
