import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  IProductCollection,
  ProductPromotionType,
} from '@core/api/Products/types'
import { productCollectionViewEvent } from '@core/events/product'
import { getProductView } from '@core/store/product-detail/selectors'
import { useServices } from '@core/utils/ioc'
import { ProductCollectionContextProvider } from '@core/utils/models/product/ProductCollectionContext'
import { usePageContext } from '@core/utils/pages/PageContext'
import useFetchAggregations from '@core/utils/useFetchAggregations'
import useFetchProducts from '@core/utils/useFetchProducts'
import PromotionContext from '../shared/PromotionContext'
import LoadingState from './LoadingState'
import { NoProductsState } from './NoProductsState'
import { ProductCollectionContent } from './ProductCollectionContent'
import { ProductCollectionControls } from './ProductCollectionControls'
import SearchTermSuggestions from './SearchTermSuggestions'
import useProductCollectionQuery from './useProductCollectionQuery'

export interface Props {
  initialData?: IProductCollection
  categoryId?: string
  brandShortId?: string
  awardShortId?: string
  hideControls?: boolean
  hidePagination?: boolean
  isPromotion?: boolean
  promotionType?: ProductPromotionType
  showSearchTermSuggestions?: boolean
  showCategoryFilter?: boolean
  isSearchResultPage?: boolean
}

interface StaticProps {
  SearchTermSuggestions: typeof SearchTermSuggestions
  NoProductsState: typeof NoProductsState
  LoadingState: typeof LoadingState
  ProductCollectionControls: typeof ProductCollectionControls
  ProductCollectionContent: typeof ProductCollectionContent
}

const ProductCollection: React.FC<Props> & StaticProps = ({
  categoryId,
  brandShortId,
  initialData,
  hideControls,
  hidePagination,
  isPromotion = false,
  promotionType,
  showSearchTermSuggestions,
  showCategoryFilter,
  isSearchResultPage,
  children,
}) => {
  const productView = useSelector(getProductView)
  const { eventBus, productQueryService } = useServices()
  const pageContext = usePageContext()
  const { query, setItemPerPageLimit, setTaxonomyFilter, removeFilter } =
    useProductCollectionQuery()

  const queryToFetch = brandShortId
    ? productQueryService.parseQueryWithBrand(brandShortId)
    : categoryId
    ? productQueryService.parseQueryWithTaxonomy(categoryId)
    : productQueryService.parseQuery()

  // disable fetching if initialData is present
  // assumes that the page does not use shallow routing
  const [productFetchData] = useFetchProducts(queryToFetch, !!initialData)

  const { taxonomyAggregationRoot, isLoading: isAggregationsLoading } =
    useFetchAggregations(queryToFetch)

  const productCollection = initialData || productFetchData.payload
  const productCollectionProductsTotal =
    productCollection && productCollection.total

  const isLoading = productFetchData.isLoading || isAggregationsLoading

  useEffect(() => {
    if (productCollection?.hits?.length) {
      eventBus.publish(
        productCollectionViewEvent(
          productCollection.hits,
          {
            isPromotion,
            promotionType,
          },
          pageContext
        )
      )
    }
  }, [eventBus, productCollection, isPromotion, promotionType, pageContext])

  if (!productCollection) return null

  return (
    <ProductCollectionContextProvider
      productCollectionContext={{
        productCollection,
        query,
        queryToFetch,
        taxonomyAggregationRoot,
        setTaxonomyFilter,
        removeFilter,
        brandShortId,
        productView,
        showCategoryFilter,
        showSearchTermSuggestions,
        hideControls,
        hidePagination,
        productCollectionProductsTotal,
        isLoading,
        setItemPerPageLimit,
        isSearchResultPage,
      }}
    >
      <PromotionContext.Provider
        value={{
          isPromotion,
          promotionType,
        }}
      >
        {children}
      </PromotionContext.Provider>
    </ProductCollectionContextProvider>
  )
}

ProductCollection.SearchTermSuggestions = SearchTermSuggestions
ProductCollection.LoadingState = LoadingState
ProductCollection.NoProductsState = NoProductsState
ProductCollection.ProductCollectionControls = ProductCollectionControls
ProductCollection.ProductCollectionContent = ProductCollectionContent

export default ProductCollection
