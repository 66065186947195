import { cloneElement } from 'react'
import { Pagination } from 'antd'
import { PaginationProps } from 'antd/lib/pagination'
import Link from 'next/link'
import qs from 'qs'
import { useServices } from '@core/utils/ioc'
import { getQueryParamsString } from '@core/utils/url'

export interface Props extends PaginationProps {
  total: number
  pageSize: number
  current: number
}

const LYSLinkPagination: React.FC<Props> = (props) => {
  const { router } = useServices()
  return (
    <Pagination
      itemRender={(page, type, originalElement) => {
        const pageTotal = Math.ceil(props.total / props.pageSize)
        const shouldRenderLink =
          type === 'page' ||
          (type === 'prev' && props.current > 1) ||
          (type === 'next' && props.current < pageTotal)

        if (!shouldRenderLink) return originalElement

        const pageQuery = { ...router.query, page }
        const stringifiedPageQuery = qs.stringify(pageQuery)
        const pathname = `${router.pathname}?${stringifiedPageQuery}`
        const asQuery = {
          ...qs.parse(getQueryParamsString(router.asPath)),
          page,
        }
        const stringifiedAsQuery = qs.stringify(asQuery)
        const as = `${router.asPath.split('?')[0]}?${stringifiedAsQuery}`

        // AntD sets page links to nofollow which we want to avoid
        if (type === 'page') {
          // @ts-ignore
          originalElement = cloneElement(originalElement, {
            // @ts-ignore
            ...originalElement?.props,
            rel: undefined,
          })
        }

        // AntD does not wrap prev and next into anchor tags so we have to add them ourselves
        if (type === 'prev' || type === 'next') {
          originalElement = <a>{originalElement}</a>
        }

        return (
          <Link as={as} href={pathname}>
            {originalElement}
          </Link>
        )
      }}
      {...props}
    />
  )
}
export default LYSLinkPagination
