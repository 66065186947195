import * as React from 'react'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { LYSButton, LYSIcon } from '@core/components/Primitives'
import useCart from '@core/hooks/cart/useCart'

import style from './index.module.less'

import { useProductContext } from '@core/utils/models/product/ProductContext'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

export interface OwnProps {
  onAddToCart: () => void
  isAllowedToAddToCart: boolean
  purchasable: boolean // needed for idealclean
  size?: SizeType // override size at places needed e.g. quickcart
  className?: string
}

const AddToCartInput: React.FC<OwnProps> = (props) => {
  const { onAddToCart, isAllowedToAddToCart, size, className } = props
  const { isCartUpdatePending } = useCart()

  const productContext = useProductContext()

  if (!productContext) return null

  return (
    <div className={className}>
      <LYSButton
        onClick={onAddToCart}
        size={size ? size : 'large'}
        actionButtonStyle={true}
        className={style.button}
        type="primary"
        disabled={!isAllowedToAddToCart || isCartUpdatePending}
        data-testid={
          isAllowedToAddToCart ? 'add-to-cart' : 'add-to-cart-disabled'
        }
      >
        <div className={style.buttonContentWrap}>
          <LYSIcon component={ShoppingCartOutlined} size={'sm'} />
        </div>
      </LYSButton>
    </div>
  )
}

export default AddToCartInput
