import React, { useState } from 'react'
import {
  IProductCollection,
  ProductPromotionType,
} from '@core/api/Products/types'
import {
  LYSButton,
  LYSCol,
  LYSIcon,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import { CategoryFilter } from '@core/components/ProductCollection/Filters/CategoryFilter'
import ProductCollection from './ProductCollection'
import style from './index.module.less'
import ProductCollectionFilters from '@core/components/ProductCollection/SideFilters'
import { usePageContext } from '@core/utils/pages/PageContext'
import { useTranslation } from '@core/i18n/i18n'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'
import RecommendationSlider from '@core/components/shared/RecommendationSlider'
import { PrismicSettingsProvider } from '@core/prismic/components/PageLayout/PrismicSettingsContext'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { SelectOutlined } from '@ant-design/icons'
import MobileSortingControl from './SortingControl/mobileSortingControl'
export interface Props {
  initialData?: IProductCollection
  categoryId?: string
  brandShortId?: string
  awardShortId?: string
  hideControls?: boolean
  hidePagination?: boolean
  isPromotion?: boolean
  promotionType?: ProductPromotionType
  showSearchTermSuggestions?: boolean
  showCategoryFilter?: boolean
  isSearchResultPage?: boolean
  showRecommendations?: boolean
}

const ProductCollectionLayout: React.FC<Props> = (props) => {
  const {
    isSearchResultPage,
    showRecommendations,
    initialData,
    showCategoryFilter,
  } = props
  const isEmptySearchResult = isSearchResultPage && initialData?.total === 0
  const containerStyle =
    isEmptySearchResult && showRecommendations
      ? style.noResultCategoryContainer
      : ''

  const {
    props: { isMobile },
  } = usePageContext()
  const { t } = useTranslation()
  const [showFilter, setShowFilter] = useState(!isMobile)
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }
  const campaignUrl = initialData?.campaignUrl
  return (
    <PrismicSettingsProvider value={{ minimized: false }}>
      <ProductCollection {...props}>
        <LYSRow gutter={'md'}>
          <ProductCollection.SearchTermSuggestions
            className={style.searchTermSuggestionContainer}
          />
          {campaignUrl && (
            <LYSCol push={0} className={style.searchTermSuggestionContainer}>
              <LYSTypography.Text>
                {t`search.searchField.campaignUrl`}
              </LYSTypography.Text>{' '}
              <a href={campaignUrl.url} target="_blank" rel="noreferrer">
                <SelectOutlined rotate={90} rev={undefined} />{' '}
                {campaignUrl.label}
              </a>
            </LYSCol>
          )}
        </LYSRow>

        {isMobile && (
          <LYSRow gutter={'xs'}>
            <LYSCol xs={8}>
              <CategoryFilter
                className={style.categoryContainer}
                containerClassName={containerStyle}
                showRootNavigation={isEmptySearchResult}
              />
            </LYSCol>
            <LYSCol xs={8}>
              {showFilter ? (
                <div className={style.overlay}>
                  <div className={style.mobileOverlayHeader}>
                    <LYSTypography.Title level={3}>
                      {t('filter.headline')}
                    </LYSTypography.Title>
                    <LYSButton
                      className={style.closeButton}
                      type={'text'}
                      onClick={handleShowFilter}
                    >
                      <LYSIcon
                        component={CloseOutlined}
                        size={'sm'}
                        title={'Close'}
                      />
                    </LYSButton>
                  </div>
                  <ProductCollectionFilters />
                </div>
              ) : (
                <LYSButton
                  icon={<FilterOutlined rev={undefined} />}
                  onClick={handleShowFilter}
                  className={style.mobileButton}
                >
                  {t('filter.headline')}
                </LYSButton>
              )}
            </LYSCol>
            <LYSCol xs={8}>
              <MobileSortingControl />
            </LYSCol>
          </LYSRow>
        )}
        <LYSRow>
          {!isMobile && showCategoryFilter ? (
            <LYSCol xs={24} md={5} className={style.categoryAndFilterWrapper}>
              <CategoryFilter
                className={style.categoryContainer}
                containerClassName={containerStyle}
                showRootNavigation={isEmptySearchResult}
              />
              {showFilter ? (
                isEmptySearchResult ? null : (
                  <ProductCollectionFilters />
                )
              ) : (
                <LYSButton
                  icon={<FilterOutlined rev={undefined} />}
                  onClick={handleShowFilter}
                  className={style.mobileButton}
                >
                  {t('filter.headline')}
                </LYSButton>
              )}
            </LYSCol>
          ) : null}

          <LYSCol
            data-testid="test-product-collection"
            className={style.categoryContainer}
            xs={24}
            md={showCategoryFilter ? 19 : 24}
          >
            {!isMobile && (
              <ProductCollection.ProductCollectionControls
                className={style.tools}
                isEmptySearchResult={isEmptySearchResult}
              />
            )}
            <ProductCollection.LoadingState className={style.productRow} />
            {isSearchResultPage && showRecommendations ? null : (
              <ProductCollection.NoProductsState className={style.noProducts} />
            )}
            <ProductCollection.ProductCollectionContent
              paginationClassName={style.pagination}
            />
            {showRecommendations ? (
              <RecommendationSlider
                recommendationType={RecommendationTypes.MOST_BOUGHT_VARIANT}
              />
            ) : null}
          </LYSCol>
        </LYSRow>
      </ProductCollection>
    </PrismicSettingsProvider>
  )
}

export default ProductCollectionLayout
